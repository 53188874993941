<template>
  <v-dialog
    v-model="dialog"
    persistent width="600"
  >
    <v-card v-if="featureList">
      <v-card-title>
        {{ $t('objectsSelected') }}: {{ featureList.length }}
        <v-spacer/>
        <v-btn
          icon
          @click="dialog=false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="featureList.length">
        <v-select
          v-model="selectedField"
          :items="fields"
          :label="$t('chooseField')"
          item-text="name"
          outlined
          return-object
        />
        <v-text-field
          v-if="selectedField"
          v-model="newValue"
          :label="$t('newValue')"
          outlined
        />
      </v-card-text>
      <v-card-text v-else class="d-flex justify-center">
        <v-progress-circular color="primary" indeterminate/>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" outlined @click="edit">
          {{ $t('apply') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/mass-actions/messages'

export default {
  name: 'MassEditDialog',
  i18n: { messages },
  data () {
    return {
      dialog: false,
      featureList: [],
      selectedAction: 'openPrintTemplateSelectorDialog',
      fields: [],
      selectedField: null,
      newValue: null,
    }
  },
  methods: {
    open (list) {
      this.featureList = []
      this.fields = []
      this.selectedField = null
      this.newValue = null
      let ids = list.map(it => it.id).join(',')
      this.$axios
        .get('layer-poi/find-by-ids', {
          params: {
            ids: ids
          }
        })
        .then(response => {
          this.featureList = response.data
          if (this.featureList.length > 0) {
            this.fields = this.featureList[0].template.fields.filter(it => it.type !== 'COMPUTED_FIELD')
          }
        })
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    edit () {
      if (!this.featuresEditable) {
        EventBus.$emit('showErrorMessage', this.$t('objectsOfDifferentLayers'))
        return
      }
      let ids = this.featureList.map(it => it.id).join(',')
      this.$axios
        .post('layer-poi/mass-edit', null, {
          params: {
            ids: ids,
            fieldId: this.selectedField.id,
            newValue: this.newValue
          }
        })
        .then(() => {
          EventBus.$emit('showInfoMessage', this.$t('dataSaved'))
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
        .finally(() => {
          this.$emit('update')
          this.close()
        })
    }
  },
  computed: {
    featuresEditable () {
      const layerId = this.featureList[0].layer.id
      return this.featureList.every(el => el.layer.id === layerId)
    }
  }
}
</script>
