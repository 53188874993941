import { render, staticRenderFns } from "./PoiStyleDialog.vue?vue&type=template&id=1ffd3457&scoped=true&"
import script from "./PoiStyleDialog.vue?vue&type=script&lang=js&"
export * from "./PoiStyleDialog.vue?vue&type=script&lang=js&"
import style0 from "./PoiStyleDialog.vue?vue&type=style&index=0&id=1ffd3457&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ffd3457",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VColorPicker,VDialog,VIcon,VMenu,VSpacer,VTextField})
