<template>
  <v-container>

    <v-card-actions>
      <span class="title">{{ $t('settings') }}</span>
      <v-spacer/>
      <v-btn @click="save" color="primary" outlined>{{ $t('button.save') }}</v-btn>
    </v-card-actions>

    <v-layout row wrap>
      <v-card class="ma-2" outlined width="280">
        <v-card-title class="subtitle-1">SMTP</v-card-title>
        <v-card-text class="py-0">
          <hidden-text-field :key="item.id" :label="item.label"
                        :hidden="item.isSecured"
                        v-for="item in mailSettings"
                        :model.sync="item.body"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" outlined @click="openTestEmail">
            {{ $t('test') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card class="ma-2" outlined width="280">
        <v-card-title class="subtitle-1">{{ $t('app') }}</v-card-title>
        <v-card-text class="py-0">
          <hidden-text-field :key="item.id" :label="item.label"
                        :hidden="item.isSecured"
                        v-for="item in generalSettings"
                        :model.sync="item.body"/>
        </v-card-text>
      </v-card>

      <v-card class="ma-2" outlined width="280">
        <v-card-title class="subtitle-1">{{ $t('notifications') }}</v-card-title>
        <v-card-text class="py-0">
          <hidden-text-field :key="item.id" :label="item.label"
                        :hidden="item.isSecured"
                        v-for="item in notificationSettings"
                        :model.sync="item.body"/>
        </v-card-text>
      </v-card>

      <v-card class="ma-2" outlined width="280">
        <v-card-title class="subtitle-1">GIS</v-card-title>
        <v-card-text class="py-0">
          <hidden-text-field :key="item.id" :label="item.label"
                        :hidden="item.isSecured"
                        v-for="item in gisSettings"
                        :model.sync="item.body"/>
        </v-card-text>
      </v-card>

      <v-card class="ma-2" outlined width="280">
        <v-card-title class="subtitle-1">Active Directory</v-card-title>
        <v-card-text class="py-0">
          <hidden-text-field :key="item.id" :label="item.label"
                             :hidden="item.isSecured"
                             v-for="item in activeDirectorySettings"
                             :model.sync="item.body"/>
        </v-card-text>
      </v-card>

      <v-card class="ma-2" outlined width="280">
        <v-card-title class="subtitle-1">FTP</v-card-title>
        <v-card-text class="py-0">
          <hidden-text-field :key="item.id" :label="item.label"
                             :hidden="item.isSecured"
                             v-for="item in ftpSettings"
                             :model.sync="item.body"/>
        </v-card-text>
      </v-card>
    </v-layout>

    <test-email-dialog ref="testEmail"/>

  </v-container>
</template>

<script>
import { EventBus } from '@/event-bus'
import messages from '../../componet-locale/application-settings/messages'
import HiddenTextField from '@/components/utils/HiddenTextField'
import TestEmailDialog from '@/components/admin/TestEmailDialog'

export default {
  name: 'ApplicationSettings',
  components: { HiddenTextField, TestEmailDialog },
  data: () => ({
    list: []
  }),
  i18n: { messages: messages },
  mounted () {
    this.$axios
      .get('settings/get-all')
      .then(response => this.list = response.data)
      .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
  },
  methods: {
    async save () {
      await this.$axios
        .post('settings/save-all', this.list)
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
      await this.$store.dispatch('setGisCredentials')
    },
    openTestEmail () {
      this.$refs.testEmail.open()
    }
  },
  computed: {
    generalSettings () {
      return this.list.filter(i => i.type === 'GENERAL')
    },
    mailSettings () {
      return this.list.filter(i => i.type === 'MAIL')
    },
    gisSettings () {
      return this.list.filter(i => i.type === 'GIS')
    },
    activeDirectorySettings () {
      return this.list.filter(i => i.type === 'ACTIVE_DIRECTORY')
    },
    ftpSettings () {
      return this.list.filter(i => i.type === 'FTP')
    },
    notificationSettings () {
      return this.list.filter(i => i.type === 'NOTIFICATION')
    }
  }
}
</script>
