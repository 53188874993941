<template>
  <v-dialog v-if="dialog" v-model="dialog" persistent width="900">
    <v-card class="overflow-x-hidden">
      <v-card-text class="px-2 py-4">
        <v-text-field
          v-for="field in fields"
          v-model="field.value"
          :label="field.headerName"
          class="mb-5"
          dense
          hide-details
          outlined
          placeholder="NULL"
        />

        <v-expand-transition>
          <div v-if="showQuery && queryHtml && setAbility('ADMIN')">
            <span v-html="queryHtml"/>
          </div>
        </v-expand-transition>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined text @click="close">{{ $t('button.close') }}</v-btn>
        <v-spacer/>
        <v-btn v-if="showQuery && setAbility('ADMIN')" :disabled="queryLoading" color="primary" icon small
               @click="loadQueryHtml">
          <v-icon small>mdi-cached</v-icon>
        </v-btn>
        <v-btn v-if="setAbility('ADMIN')" :loading="queryLoading" outlined @click="changeShowQueryMode">
          {{ showQuery ? $t('hideQuery') : $t('showQuery') }}
        </v-btn>
        <v-btn color="primary" outlined @click="save">{{ $t('button.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/et-insert/messages'

export default {
  name: "EtAddRowDialog",
  i18n: { messages },
  data: () => ({
    dialog: false,
    fields: [],
    queryHtml: '',
    showQuery: false,
    queryLoading: false
  }),
  props: {
    tableId: {
      required: true,
      type: Number
    }
  },
  methods: {
    init () {
      this.$axios
        .get('/et/get-insert-info', {
          params: {
            tableId: this.tableId
          }
        })
        .then(res => {
          this.fields = res.data.insertFields
        })
        .catch(({ data: e }) => {
          EventBus.$emit('showErrorMessage', this.$t(e.description || e.message || 'error'))
          this.close()
        })
    },
    open () {
      this.dialog = true
      this.init()
    },
    close () {
      this.dialog = false
      this.fields = []
    },
    changeShowQueryMode () {
      if (this.showQuery) {
        this.showQuery = false
        this.queryHtml = ''
      } else {
        this.loadQueryHtml()
      }
    },
    loadQueryHtml () {
      this.queryLoading = true
      this.$axios.post('/et/get-insert-query', {
        tableId: this.tableId,
        insertFields: this.fields
      })
        .then(res => {
          this.showQuery = true
          this.queryHtml = `<span>${res.data}</span>`
        })
        .catch(e => EventBus.$emit('showErrorMessage', this.$t(e.description || e.message || 'error')))
        .finally(() => this.queryLoading = false)
    },
    save () {
      this.$axios.post('/et/insert', {
        tableId: this.tableId,
        insertFields: this.fields
      })
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
          this.$emit('save')
          this.fields.forEach(it => it.value = "")
        })
        .catch(e => EventBus.$emit('showErrorMessage', this.$t(e.description || e.message || 'error')))
    }
  }
}
</script>
