<template>
  <div>
    <div
      v-for="value in properties"
      v-if="poi.properties"
    >
      <v-textarea
        v-if="value.field.type === 'STRING'"
        v-model="value.string"
        :label="value.field.name"
        :readonly="readonlyMode"
        auto-grow
        dense
        outlined
        rows="1"
      />

      <v-text-field
        v-if="value.field.type === 'LINK'"
        v-model="value.value"
        :label="value.field.name"
        :readonly="readonlyMode"
        dense
        outlined
      >
        <template v-slot:append>
          <v-icon @click="openLink(value)">
            open_in_new
          </v-icon>
        </template>
      </v-text-field>

      <v-text-field
        v-if="value.field.type === 'CUSTOM_LINK'"
        v-model="value.value"
        :label="value.field.name"
        :readonly="readonlyMode"
        dense
        outlined
      >
        <template v-slot:append>
          <v-icon @click="openLink(value)">
            open_in_new
          </v-icon>
        </template>
      </v-text-field>

      <v-text-field
        v-if="value.field.type === 'PARAMETRIC_LINK'"
        :label="value.field.name"
        :value="createParametricLink(value)"
        dense
        outlined
        readonly
      >
        <template v-slot:append>
          <v-icon @click="openLink(value)">
            open_in_new
          </v-icon>
        </template>
      </v-text-field>

      <date-field
        v-if="value.field.type === 'DATE'"
        v-model="value.date"
        :label="value.field.name"
      />
      <v-select
        v-if="value.field.type === 'LIST'"
        v-model="value.value"
        :items="getOptions(value)"
        :label="value.field.name"
        :readonly="readonlyMode"
        dense
        outlined
      />

      <multiple-list-field
        v-if="value.field.type === 'MULTIPLE_LIST'"
        :readonlyMode="readonlyMode"
        :value="value"
      />

      <number-field
        v-if="value.field.type === 'NUMBER'"
        :readonly="readonlyMode"
        :value="value"
      />

      <list-from-data-table-field
        v-if="value.field.type === 'DATA_TABLE'"
        :readonly="readonlyMode"
        :value="value"
      />

      <list-from-data-table-field
        v-if="value.field.type === 'DATA_TABLE_MULTIPLE'"
        :readonly="readonlyMode"
        :value="value"
        multiple
      />

      <v-switch
        v-if="value.field.type === 'BOOLEAN'"
        v-model="value.boolean"
        :label="value.field.name"
        :readonly="readonlyMode"
        dense
      />

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-if="value.field.type === 'COMPUTED_FIELD'"
            :label="value.field.name"
            :value="makeCalculation(value)"
            dense
            outlined
            readonly>
            <template v-slot:append>
              <v-icon
                v-on="on"
                v-html="'info_outline'"
              />
            </template>
          </v-text-field>
        </template>
        <v-card class="pa-3">
          {{ value.field.options }}
        </v-card>
      </v-menu>

    </div>
  </div>
</template>

<script>
import DateField from '@/components/layer-poi/DateField'
import MultipleListField from '@/components/layer-poi/MultipleListField'
import NumberField from '@/components/layer-poi/NumberField'
import ListFromDataTableField from '@/components/layer-poi/ListFromDataTableField'

export default {
  name: 'LayerPoiFields',
  components: { ListFromDataTableField, NumberField, MultipleListField, DateField },
  props: {
    poi: Object,
    readonlyMode: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getOptions (value) {
      let items = value.field.options.split(',')
      if (!items.includes(value.value)) {
        items.push(value.value)
      }
      return items
    },
    initAdditionalFields () {
      this.poi.template.fields
        .forEach(field => {
          if (!this.poi.properties.find(prop => prop.field.id === field.id)) {
            this.poi.properties.push({ field: field })
          }
        })
      this.poi.properties.sort((a, b) => {
        return a.field.index > b.field.index ? 1 : -1
      })
    },
    makeCalculation (value) {
      let it = this

      function evil (fn) {
        return new Function('return ' + fn)()
      }

      function findValue (fieldName) {
        let value = it.poi.properties.find(prop => prop.field.name === fieldName)
        return value ? value.number : 0
      }

      function replacer (val) {
        let fieldName = val
          .replaceAll('[', '')
          .replaceAll(']', '')
        return findValue(fieldName)
      }

      let formula = value.field.options.replaceAll(/\[.*?\]/g, replacer)

      let result = evil(formula)
      value.value = result.toFixed(2)
      return result.toFixed(2).toString().replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ')
    },
    openLink (value) {
      let url
      if (value.field.type === 'CUSTOM_LINK') {
        url = value.field.options + value.value
      } else if (value.field.type === 'PARAMETRIC_LINK') {
        url = this.createParametricLink(value)
      } else {
        url = value.value
      }
      window.open(url, '_blank')
    },
    createParametricLink (value) {
      let it = this

      function replacer (val) {
        const fieldName = val
          .replaceAll('[', '')
          .replaceAll(']', '')
        const value = it.poi.properties.find(prop => prop.field.name === fieldName)
        return value.string || value.value || value.field.options || ''
      }

      return value.field.options.replaceAll(/\[.*?\]/g, replacer)
    }
  },
  created () {
    this.initAdditionalFields()
  },
  watch: {
    poi (val) {
      if (val) this.initAdditionalFields()
    }
  },
  computed: {
    properties () {
      let fieldsIdInTemplate = this.poi.template.fields.map(it => it.id)
      return this.poi.properties.filter(prop => fieldsIdInTemplate.includes(prop.field.id))
    }
  }
}
</script>
