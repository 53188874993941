<template>
  <tr :key="item.id" :bgcolor="item.id === selectedDataId ? '#f1f1f1' : '#ffffff'">
    <td v-show="selectable" class="pa-3">
      <v-checkbox v-model="item.selected" class="ma-0 pa-0" dense hide-details @change="$emit('selectHandler')"/>
    </td>
    <td class="inline-text pointer" style="max-width: 250px !important;" @click="open(item, $event)">
      <span :style="item.isActive? '': 'text-decoration: line-through'">{{ item.name }}</span>
    </td>
    <td v-if="additionalField" class="inline-text " style="max-width: 100px !important;" @click="open(item, $event)">
      <v-chip :style="backgroundFromString(item[additionalField])" dark label small>
        {{ item[additionalField] }}
      </v-chip>
    </td>
    <td v-show="isDataListView && !displayXS" class="inline-text" style="max-width: 250px !important;"
        @click="open(item, $event)">
      <v-chip v-for="category in item.categoryList.filter(it => !it.isDefault)" class="ma-1" color="deep-purple" label outlined
              small>
        {{ category.name }}
      </v-chip>
    </td>
    <td v-show="isDataListView && !displayXS" class="inline-text" style="max-width: 250px !important;"
        @click="open(item, $event)">
      <v-chip v-for="keyword in item.keywords" class="ma-1" color="primary" label outlined small>
        {{ keyword.value }}
      </v-chip>
    </td>
    <td v-show="isDataListView && !displayXS" class="inline-text pointer" @click="open(item, $event)">
      {{ formatDate(item.creationDate) }}
    </td>

    <td v-show="isDataListView" class="pointer"
        style="text-align: right; color: #5c5c5c; width: 20px">
      <v-menu :nudge-right="5" :nudge-width="200" offset-x>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <slot name="actions"/>
            <v-list-item
              v-for="(item, index) in options" :key="index"
              @click="invoke(item.action)"
              v-show="setAbility(item.role)"
              dense>
              <v-list-item-avatar>
                <v-icon :color="item.color">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                {{ $t(item.title) }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </td>

    <abstract-data-details-dialog ref="itemDetailsDialog" @save="save"/>
    <abstract-data-info-dialog ref="infoDialog"/>
    <share-data-dialog ref="shareDataDialog"/>

    <confirmation-dialog ref="confirmationDialog" :title="$t('confirmDeletion')" @confirm="confirmRemove">
      <v-btn v-if="setAbility('ADMIN')"
             color="red" outlined
             @click="removeForever">
        {{ $t('remove') }}
      </v-btn>
    </confirmation-dialog>
  </tr>
</template>

<script>
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import AbstractDataDetailsDialog from '@/components/utils/AbstractDataDetailsDialog'
import AbstractDataInfoDialog from '@/components/utils/AbstractDataInfoDialog'
import ShareDataDialog from '@/components/utils/ShareDataDialog'
import { EventBus } from '@/event-bus'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog.vue'
import messages from '../../componet-locale/view-data-page/messages'

export default {
  name: 'AbstractDataRow',
  components: { ConfirmationDialog, ShareDataDialog, AbstractDataInfoDialog, AbstractDataDetailsDialog },
  props: ['item', 'isDataListView', 'selectedDataId', 'selectable', 'additionalField'],
  mixins: [displayServiceMixin],
  i18n: { messages },
  methods: {
    invoke (action) {
      this[action]()
    },
    showInfo () {
      this.$refs.infoDialog.open(this.item.id)
    },
    async edit () {
      let { data } = await this.$axios.get('data/get', { params: { id: this.item.id } })
      data.position = data.position ? JSON.parse(data.position) : null
      this.$refs.itemDetailsDialog.editData(data)
    },
    share () {
      let url = `${window.location}/${this.item.id}`
      this.$refs.shareDataDialog.share(url)
    },
    save (data) {
      this.$axios
        .post('data/save', data)
        .then(EventBus.$emit('showSuccessMessage'))
    },
    open (event) {
      this.$emit('open', this.item, event)
    },
    remove () {
      this.$refs.confirmationDialog.open(this.data)
    },
    confirmRemove () {
      this.$axios
        .get('/data/remove', { params: { id: this.item.id } })
        .then(() => {
          EventBus.$emit('closeViewDataPage')
          EventBus.$emit('loadData')
        })
        .catch(error => console.error(error))
    },
    removeForever () {
      this.$axios
        .get('/data/remove-forever', { params: { id: this.item.id } })
        .then(() => {
          EventBus.$emit('closeViewDataPage')
          EventBus.$emit('loadData')
        })
        .catch(error => console.error(error))
    }
  },
  computed: {
    options () {
      return [
        {
          title: 'button.info',
          icon: 'info',
          color: 'gray',
          action: 'showInfo',
          role: 'USER',
          visible: true
        },
        {
          title: 'button.edit',
          icon: 'edit',
          color: 'gray',
          action: 'edit',
          role: 'MODERATOR',
          visible: true
        },
        {
          title: 'button.share',
          icon: 'share',
          color: 'gray',
          action: 'share',
          role: 'USER',
          visible: true
        },
        {
          title: 'button.remove',
          icon: 'delete',
          color: 'red',
          action: 'remove',
          role: 'ADMIN',
          visible: true
        },
      ]
    }
  }
}
</script>
