<template>
  <img
    v-if="displayImg"
    class="d-inline-block mr-3"
    width="20"
    :src="displayImg"
  />
  <div
    v-else
    :class="`d-inline-block mr-3 ${styleClass}`"
    :style="style"
  />
</template>

<script>
export default {
  name: 'LayerPoiBadge',
  props: {
    styleId: Number,
    template: Object
  },
  computed: {
    style () {
      let style = this.ruleTemplate ? this.ruleTemplate.layerPoiStyle : this.template.defaultLayerPoiStyle
      let stroke = style.stroke || 'blue'
      let fill = style.fill || 'blue'
      return `background: ${fill}; border: 3px solid ${stroke};`
    },
    styleClass () {
      switch (this.template.graphType) {
        case 'POINT' :
          return 'layer-poi-badge-circle'
        case 'LINE_STRING' :
        case 'MULTI_LINE_STRING' :
          return 'layer-poi-badge-line'
        case 'POLYGON' :
        case 'MULTI_POLYGON' :
          return 'layer-poi-badge'
      }
    },
    ruleTemplate () {
      if (this.template.rules.find(el => el.layerPoiStyle.id === this.styleId)) {
        return this.template.rules.find(el => el.layerPoiStyle.id === this.styleId)
      }
      return null
    },
    displayImg () {
      if (this.ruleTemplate) {
        return this.ruleTemplate.layerPoiStyle.icon !== '' ? this.ruleTemplate.layerPoiStyle.icon : null
      } else {
        return this.template.defaultLayerPoiStyle.icon !== '' ? this.template.defaultLayerPoiStyle.icon : null
      }
    }
  }
}
</script>

<style scoped>
.layer-poi-badge {
  width: 20px;
  height: 20px;
}

.layer-poi-badge-circle {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.layer-poi-badge-line {
  width: 20px !important;
}
</style>
