<template>
  <v-menu
    :position-x="position.x"
    :position-y="position.y"
    absolute
    id="FeatureSelector"
    style="z-index: 2000"
    v-model="menu"
  >
    <v-list>
      <v-list-item
        dense
        @click="showFeature(feature)"
        v-for="feature in featureList"
      >
        <v-list-item-avatar>
          <v-icon small>layers</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ feature.layer.name }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="feature.template">
            {{ feature.template.name }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'FeatureSelector',
  data: () => ({
    menu: false,
    position: { x: 0, y: 0 },
    featureList: []
  }),
  methods: {
    open (featureList, position) {
      this.position = { ...position }
      this.featureList = { ...featureList }
      this.menu = true
    },
    showFeature (feature) {
      this.$emit('show', feature)
    }
  }
}
</script>
