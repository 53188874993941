<template>
  <div>
    <vuedraggable handle=".handle" v-model="layerList">
      <layer-setting-item
        v-for="layer in filteredLayers"
        :layer="layer"
        @refresh-map="refreshMap"
        @remove="remove"
        @openTable="openTable"
      />
    </vuedraggable>
  </div>
</template>

<script>
import Vuedraggable from 'vuedraggable'
import LayerSettingItem from './LayerSettingItem'
import { EventBus } from '@/event-bus'

export default {
  name: 'MapConfig',
  props: {
    layers: {
      type: Array,
      default: []
    },
    data: {
      type: Object
    },
    sandbox: {
      type: Boolean,
      default: false
    },
    searchField: {
      type: String,
      default: ''
    }
  },
  components: { LayerSettingItem, Vuedraggable },
  methods: {
    refreshMap () {
      EventBus.$emit('frameResize')
    },
    remove (layer) {
      this.layers.splice(this.layers.indexOf(layer), 1)
      this.$emit('deleteLayer', layer)
      EventBus.$emit('frameResize')
    },
    openTable (layer) {
      if (!this.data) return

      if (layer.type === 'LAYER_POI') {
        this.$router.push({
          name: 'poi-table',
          params: {
            layerId: layer.id
          }
        })
      } else {
        this.$router.push({
          path: '/split-screen',
          query: {
            dataId: layer.id,
            secondDataId: this.data.id
          }
        })
      }
    }
  },
  watch: {
    layerList () {
      this.refreshMap()
    }
  },
  computed: {
    filteredLayers () {
      return this.layers
        .filter(item => item.name.toLowerCase().indexOf(this.searchField.toLowerCase()) > -1)
    },
    layerList: {
      get () {
        return this.layers
      },
      set (val) {
        this.$emit('update:layers', val)
      }
    }
  }
}
</script>
