<template>
  <div>
    <div v-if="graphType">
      <vl-interaction-draw :type="graphType" source="draw-target">
        <vl-source-vector ref="sourceVectorDraw" :features.sync="features" ident="draw-target"/>
      </vl-interaction-draw>
      <vl-interaction-modify source="draw-target"/>
      <vl-interaction-snap source="draw-target"/>

      <div style="position:absolute; right: 0; padding: 40px; z-index: 200;">
        <v-btn
          @click="cancel"
        >
          Отменить
        </v-btn>

        <v-btn
          class="ml-4"
          color="primary"
          @click="drawend"
        >
          Сохранить
        </v-btn>
      </div>
    </div>

    <v-dialog
      v-model="confirmationDialog"
      persistent
      scrollable
      width="600"
    >
      <v-card>
        <v-card-title>
          Создать объект
          <v-spacer/>
          <v-btn
            icon
            @click="cancelDrawing"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <wfs-fields v-if="selected && selected.layer.type === 'WMS'" :feature="selected"/>
          <layer-poi-fields v-if="selected && selected.layer.type === 'LAYER_POI'" :poi="selected"/>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mr-2"
            outlined
            @click="cancelDrawing"
          >
            Отменить
          </v-btn>
          <v-spacer/>
          <v-btn
            color="primary"
            dark
            outlined @click="createFeature">
            <v-icon left>save</v-icon>
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      width="600"
    >
      <v-card>
        <v-card-title>
          Создать объект
          <v-spacer/>
          <v-btn
            icon
            @click="cancel"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <template v-for="layer in visibleLayers">
            <div class="subtitle-2 pa-2">{{ layer.name }}</div>
            <v-list v-if="layer.type === 'ZWS'" dense subheader>
              <template v-for="type in layer.typeList" v-if="type.tipeID !== -1 && type.typeId !== '-1' ">
                <v-subheader>{{ type.name }}</v-subheader>
                <v-list-item v-for="mode in type.modes" dense @click="create(type, mode, layer)">
                  <img :src="mode.image" class="d-inline-block mr-3" width="20"/>
                  <span class="caption">{{ mode.title }}</span>
                </v-list-item>
                <v-divider/>
              </template>
            </v-list>

            <v-list v-if="layer.type === 'WMS'" dense subheader>
              <v-list-item v-if="layer._ds && layer._ds.typeName" @click="createWFS(layer)">
                {{ layer._ds.typeName }}
              </v-list-item>
            </v-list>

            <v-list v-if="layer.type === 'LAYER_POI'" dense subheader>
              <v-list-item v-for="template in layer.poiTemplates" dense @click="createPoi(template, layer)">
                <layer-poi-badge :template="template"/>
                <span class="caption">
                  {{ template.name }}
                </span>
              </v-list-item>
              <v-divider/>
            </v-list>

          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import ZwsCommandBuilder from '@/services/zws-command-builder'
import { EventBus } from '@/event-bus'
import LayerPoiFields from '@/components/layer-poi/LayerPoiFields'
import LayerPoiBadge from '@/components/layer-poi/LayerPoiBadge'
import { GML, WFS } from 'ol/format'
import WfsFields from '@/components/map/geoserver/WfsFeatureFields'

export default {
  name: 'FeatureCreationDialog',
  components: { WfsFields, LayerPoiBadge, LayerPoiFields },
  props: {
    layers: Array,
    state: Object
  },
  data: () => ({
    features: [],
    feature: {},
    selected: null,
    drawEnd: false,
    dialog: false,
    confirmationDialog: false,
    coordinate: {},
    graphType: ''
  }),
  methods: {
    open (coordinate) {
      this.state.editMode = true
      this.coordinate = coordinate
      if (this.visibleLayers.length) {
        this.dialog = true
      } else {
        EventBus.$emit('showInfoMessage', 'Нет доступных слоев')
      }
    },
    drawend () {
      this.drawEnd = true
      this.confirmationDialog = true
    },
    cancel () {
      this.dialog = false
      this.features = []
      this.graphType = null
      this.drawEnd = false
      this.state.editMode = false
      this.confirmationDialog = false
    },
    async createFeature () {
      switch (this.selected.layer.type) {
        case 'ZWS':
          await this.saveZwsFeature()
          break
        case 'LAYER_POI':
          await this.savePoi()
          break
        case 'WMS':
          await this.saveWfsFeature()
          break
      }

      this.features = []
      this.graphType = ''
      this.drawEnd = false
      this.state.editMode = false
      this.confirmationDialog = false
      EventBus.$emit('refreshMap')
    },
    async saveZwsFeature () {
      let objects = this.features.map(it => getCoordinates(it.geometry, this.graphType))

      function getCoordinates (geometry, type) {
        if (type === 'Point') {
          return geometry.coordinates
        } else if (type === 'Polygon') {
          return geometry.coordinates[0].map(c => [c[1], c[0]]).join(`
      `)
        } else {
          return geometry.coordinates.map(c => [c[1], c[0]]).join(`
      `)
        }
      }

      for (let coordinates of objects) {
        if (this.graphType === 'Polygon') {
          await ZwsCommandBuilder.layerAddPolygon(this.selected, coordinates)
        } else if (this.graphType === 'LineString') {
          await ZwsCommandBuilder.LayerAddPolyline(this.selected, coordinates)
        } else if (this.graphType === 'Point') {
          await ZwsCommandBuilder.layerAddSymbol(this.selected, coordinates)
        }
      }
    },
    async savePoi () {
      let layerPois = []
      let { graphType } = this.selected.template
      if (graphType === 'MULTI_POLYGON' || graphType === 'MULTI_LINE_STRING') {
        let geometry = {
          coordinates: this.features.map(it => it.geometry.coordinates),
          type: graphType === 'MULTI_POLYGON' ? 'MultiPolygon' : 'MultiLineString'
        }
        layerPois = [{
          layer: this.selected.layer,
          template: this.selected.template,
          geometry: JSON.stringify(geometry),
          properties: this.selected.properties
        }]
      } else {
        layerPois = this.features
          .map(it => {
            return {
              layer: {
                id: this.selected.layer.id
              },
              template: {
                id: this.selected.template.id
              },
              geometry: JSON.stringify(it.geometry),
              properties: this.selected.properties
            }
          })
      }
      await this.$axios.post('layer-poi/save-all', layerPois)
      this.$emit('loadLayerPoiList', this.selected.layer)
    },

    cancelDrawing () {
      this.features = []
      this.graphType = null
      this.drawEnd = false
      this.state.editMode = false
      this.confirmationDialog = false
      EventBus.$emit('refreshMap')
    },
    createWFS (layer) {
      this.selected = {
        layer: layer,
        featureTypes: layer._ds.featureTypes,
        values_: {}
      }
      let graphType = layer._ds.featureTypes.find(it => it.name === 'the_geom')
      this.$emit('editMode')
      this.graphType = graphType.localType
      this.dialog = false
    },
    createPoi (template, layer) {
      this.selected = {
        layer: layer,
        template: template,
        properties: []
      }

      switch (template.graphType) {
        case 'POINT':
          this.$emit('editMode')
          this.graphType = 'Point'
          break
        case 'MULTI_POLYGON':
        case 'POLYGON':
          this.$emit('editMode')
          this.graphType = 'Polygon'
          break
        case 'MULTI_LINE_STRING':
        case 'LINE_STRING':
          this.$emit('editMode')
          this.graphType = 'LineString'
          break
      }
      this.dialog = false
    },
    async create (type, mode, layer) {
      this.selected = {
        layer: layer,
        typeId: type.typeId,
        mode: mode
      }
      switch (type.graphType) {
        case 'Point':
          this.$emit('editMode')
          this.graphType = 'Point'
          break
        case 'Area':
          this.$emit('editMode')
          this.graphType = 'Polygon'
          break
        case 'Line':
          this.$emit('editMode')
          this.graphType = 'LineString'
          break
      }
      this.dialog = false
    },
    saveWfsFeature () {
      let feature = this.$refs.sourceVectorDraw.getFeatures()[0]
      this.selected.values_.geometry = feature.getGeometry()
      this.selected.values_.the_geom = feature.getGeometry()
      feature.values_ = this.selected.values_

      let { layer } = this.selected
      let formatWFS = new WFS()
      let gml = new GML({
        featureNS: layer._ds.targetNamespace,
        featurePrefix: layer._ds.targetPrefix,
        featureType: layer._ds.typeName,
        srsName: 'EPSG:3857'
      })
      let node = formatWFS.writeTransaction([feature], null, null, gml)
      let s = new XMLSerializer()
      let value = s.serializeToString(node)
      this.$axios
        .post(window.location.origin + '/geoserver/wfs', value, {
          headers: {
            'Content-Type': 'text/xml'
          },
          params: {
            service: 'wfs'
          }
        })
        .then(() => {
          EventBus.$emit('showSuccessMessage', 'Сохранено')
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', 'Ошибка')
        })
    }
  },
  computed: {
    visibleLayers () {
      return this.layers
        .filter(layer => layer.visible && layer.type !== 'XYZ')
    }
  }
}
</script>
