<template>
  <v-navigation-drawer
      v-model="drawer"
      :width="displayXS ? '100%' : '80%'"
      style="z-index: 1000"
      absolute
      height="100vh"
      right
      temporary
  >
    <!--    TODO: check styles -->
    <v-container
        v-if="drawer"
        class="pa-0"
        fluid
    >
      <v-row class="ma-0">
        <v-col
            v-show="(!primaryChat && displayXS) || !displayXS"
            :cols="displayXS ? 12 : 'auto'"
            class="pa-0"
            style="min-width: 375px !important; width:375px;">
          <v-card
              key="list"
              class="elevation-0 align-stretch second-color fill-height"
              dark
              tile
          >
            <v-card-title
                class="pa-2 pl-3"
                style="height: 54px"
            >
              <v-text-field
                  ref="search_field"
                  v-model="searchField"
                  autocomplete="off"
                  dark
                  dense
                  flat
                  hide-details
                  placeholder="Поиск по чатам"
                  solo-inverted
                  @blur="blur"
                  @focus="inSearch=true"
              >
                <template v-slot:append-outer>
                  <v-icon
                      v-if="!inSearch"
                      @click="$refs.new_chat.open()"
                      v-text="'create'"
                  />
                </template>
                <template v-slot:prepend>
                  <div v-show="archivedChats.length && !inSearch">
                    <v-icon
                        v-if="archivedChatsShown"
                        @click="showAllChats"
                        v-text="'arrow_back'"
                    />
                    <v-icon
                        v-else
                        @click="showArchivedChats"
                        v-text="'archive'"
                    />
                    <v-badge
                        v-else
                        :value="numberOfUnreadMessagesArchivedChats"
                        color="blue"
                        dot
                        overlap
                    />
                  </div>
                </template>
                <template v-slot:append>
                  <v-icon
                      v-if="inSearch"
                      light
                      @click="closeSearch"
                      v-text="'close'"
                  />
                </template>
              </v-text-field>
            </v-card-title>

            <v-divider light/>

            <v-progress-circular
                v-if="!establishedConnection"
                class="text-center full-width mt-4"
                color="white"
                indeterminate
            />

            <v-fade-transition>
              <chat-list
                  v-show="chats.length && !inSearch"
                  :primary-chat.sync="primaryChat"
              />
            </v-fade-transition>

            <v-fade-transition>
              <filter-chat-list
                  v-if="inSearch"
                  :value.sync="searchField"
              />
            </v-fade-transition>
          </v-card>
        </v-col>

        <v-col
            v-show="(primaryChat && displayXS) || !displayXS"
            class="pa-0"
        >
          <chat-frame @back="primaryChat=null"/>
        </v-col>

      </v-row>
    </v-container>

    <chat-creation-dialog ref="new_chat"/>

  </v-navigation-drawer>
</template>

<script>
import FilterChatList from '@/components/chats/FilterChatList'
import { chatMixin } from '@/components/chats/mixins/chat-mixin'
import { EventBus } from '@/event-bus'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import { mapActions, mapGetters } from 'vuex'
import messages from '../../componet-locale/chat-container/messages'
import ChatCreationDialog from './ChatCreationDialog'
import ChatFrame from './ChatFrame'
import ChatList from './ChatList'

export default {
  name: 'ChatContainer',
  components: { FilterChatList, ChatList, ChatFrame, ChatCreationDialog },
  mixins: [displayServiceMixin, chatMixin],
  data: () => ({
    inSearch: false,
    primaryChat: null,
    drawer: false,
    searchField: ''
  }),
  i18n: { messages: messages },
  methods: {
    ...mapActions(['showArchivedChats', 'showAllChats']),
    blur () {
      setTimeout(() => this.inSearch = false, 200)
      // this.searchField = null
    },
    closeSearch () {
      this.searchField = null
      this.inSearch = false
      this.$refs.search_field.blur()
    }
  },
  mounted () {
    EventBus.$on('show-chats', (activeChat) => {
      this.primaryChat = activeChat
      this.drawer = true
    })

    // this messy workaround is needed only to open chat view in case of opening the application via link
    // e.g. [https://datrics.oo/chats/?chat=`some id`]
    const url = this.$route.matched[0].path
    if (url === '/chats') {
      this.drawer = true
    }
  },
  computed: {
    ...mapGetters([
      'archivedChats',
      'archivedChatsShown',
      'chats',
      'establishedConnection',
      'numberOfUnreadMessagesArchivedChats'
    ])
  },
  watch: {
    // this messy workaround is needed to set active chat's id from url [https://datrics.oo/chats/?chat=`chat id`]
    // only after websocket connection was established
    establishedConnection (newVal) {
      const url = this.$route.matched[0].path
      if (newVal && url === '/chats') {
        this.$store.dispatch('setActiveChatId', Number(this.$route.query.chat))
      }
    }
  }
}
</script>

<style scoped>
.v-navigation-drawer--absolute {
  position: fixed;
}

@media only screen and (max-width: 600px) {
  .chat-list {
    height: calc(100vh - 112px);
  }
}
</style>
