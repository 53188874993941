<template>
  <v-dialog v-if="dialog" v-model="dialog" width="700">
    <v-card class="overflow-x-hidden" height="90vh">
      <v-card-text class="pa-5 overflow-auto" style="height: calc(90vh - 52px)">
        <div v-for="(advice, i) in advices" class="mb-5">
          <b>{{ i + 1 }}. {{ advice.title }}</b>
          <ul>
            <li v-for="point in advice.points">{{ point }}</li>
          </ul>
          <div class="d-flex justify-center">
            <v-text-field
              v-for="example in advice.examples"
              :label="example.label"
              :value="example.text"
              class="mt-3 px-5"
              dense
              hide-details outlined readonly/>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="elevation-2">
        <v-spacer/>
        <v-btn outlined text @click="close">{{ $t('button.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "EtAdvicesDialog",
  data: () => ({
    dialog: false,

    advices: [
      {
        title: 'FROM',
        points: [
          'Необходимо описать обращение к таблицам',
          'Обязательно использовать псеводнимы через ключевое слово "AS"'
        ],
        examples: [{
          label: 'FROM',
          text: 'table_1 AS t1 JOIN table_2 AS t2'
        }],
      },
      {
        title: 'WHERE',
        points: [
          'Необязательный блок указания условий запроса',
          'При обращении к полям используется синтаксис [Псевдоним таблицы].[Ключ поля]'
        ],
        examples: [{
          label: 'WHERE',
          text: 't1.name = \'Ivan\''
        }],
      },
      {
        title: 'GROUP BY',
        points: [
          'Необязательный блок указания полей группировки',
          'При обращении к полям используется синтаксис [Псевдоним таблицы].[Ключ поля]'
        ],
        examples: [{
          label: 'GROUP BY',
          text: 't1.name, t1.lastname'
        }],
      },
      {
        title: 'ORDER BY',
        points: [
          'Необязательный блок указания принципов сортировки.',
          'При обращении к полям используется синтаксис [Псевдоним таблицы].[Ключ поля]'
        ],
        examples: [{
          label: 'ORDER BY',
          text: 't1.name DESC, t1.lastname'
        }],
      },
      {
        title: 'PRIMARY TABLE NAME',
        points: [
          'Указывается название главной таблицы по которой будут проходить обновления (PRIMARY TABLE)',
          'Если таблица не указана, обновление записей будет запрещено',
          'Не используется псевдоним'
        ],
        examples: [{
          label: 'PRIMARY TABLE NAME',
          text: 'table_1'
        }],
      },
      {
        title: 'Название в базе данных',
        points: [
          'Указывается название поля с использованием псевдонима талицы',
          'Синтаксис [Псевдоним таблицы].[Ключ поля]',
          'Допустимо использование агрегирующих функций'
        ],
        examples: [
          {
            label: 'Название в базе данных',
            text: 't1.name'
          },
          {
            label: 'Название в базе данных',
            text: 'count( distinct t1.name )'
          }],
      },
      {
        title: 'Псевдоним',
        points: [
          'Указывается произвольный допустимый в SQL псевдоним',
          'Обязательно уникальный внутри данной таблицы'
        ],
        examples: [{
          label: 'Псевдоним',
          text: 'user_name'
        }],
      },
      {
        title: 'Название',
        points: [
          'Указывается люое отображаемое в интерфейсе название'
        ],
        examples: [{
          label: 'Название',
          text: 'Имя пользователя'
        }],
      },
      {
        title: 'Тип поля',
        points: [
          'Указывается тип поля соответствующий ожидаемому поведению'
        ],
        examples: [],
      },
      {
        title: 'Вычисляемое поле',
        points: [
          'Обзательно указать при использовании агргирующих функций в ключе поля',
          'Необходимо для коректной работы системы'
        ],
        examples: [],
      },
      {
        title: 'Исключить из таблицы',
        points: [
          'Указывается, если необходимо искулючить поле из отображения базы в табличном виде',
          'При указании поле все равно отображается внутри диалогового окна записи'
        ],
        examples: [],
      },
      {
        title: 'Первичный ключ',
        points: [
          'Указывается, если данное поле является первичным ключом PRIMARY TABLE'
        ],
        examples: [],
      },
      {
        title: 'Использовать при добавлении',
        points: [
          'Доступно только при указании первичного ключа таблицы',
          'Указывается, если данное поле необходимо использовать при добавлении новой записи'
        ],
        examples: [],
      },
      {
        title: 'Обновление через родительскую таблицу',
        points: [
          'Указывается если ожидается, что при попытке изменения поля будет обноляться другое поле ',
          'Обновляемые поля всегда принадлежат PRIMARY TABLE',
          'При указании необходимо заполнить дополнительные поля, описанные в этом разделе'
        ],
        examples: [],
      },
      {
        title: 'Связь с картой',
        points: [
          'Указывается если ожидается, что при клике на данное поле будет просиходить адресация на объект на карте',
          'При указании необходимо заполнить дополнительные поля, описанные в этом разделе'
        ],
        examples: [],
      },
      {
        title: 'Название дочерней таблицы',
        points: [
          'Доступно только для полей обновлемых через родительскую таблицу',
          'Указывается название таблицы, к которой относится данное поле без псевдонима',
        ],
        examples: [{
          label: 'Название дочерней таблицы',
          text: 'table_2'
        }],
      },
      {
        title: 'Первичный ключ дочерней таблицы',
        points: [
          'Доступно только для полей обновлемых через родительскую таблицу',
          'Указывается первичный ключ таблицы, указанной в  "Название дочерней таблицы", без пседонима'
        ],
        examples: [{
          label: 'Первичный ключ дочерней таблицы',
          text: 'id'
        }],
      },
      {
        title: 'Ключ обновляемого поля',
        points: [
          'Доступно только для полей обновлемых через родительскую таблицу',
          'Указывается ключ поля, которое необходимо обновить, без псевдонима'
        ],
        examples: [{
          label: 'Ключ обновляемого поля',
          text: 'name'
        }],
      },
      {
        title: 'Ключ поля связи',
        points: [
          'Доступно только для полей имеющих связь с картой',
          'Указывается ключ поля, в котором находится id объекта на карте'
        ],
        examples: [{
          label: 'Ключ поля связи',
          text: 'poi_id'
        }],
      },
      {
        title: 'Уникальный псевдоним',
        points: [
          'Доступно только для полей имеющих связь с картой',
          'Указывается уникальный псевдоним допустимый в SQL'
        ],
        examples: [{
          label: 'Уникальный псевдоним',
          text: 'id_of_map_object'
        }],
      },
      {
        title: 'Варианты поиска',
        points: [
          'Доступно только для полей типа Список',
          'Указываются варианты поиска значений через запятую'
        ],
        examples: [{
          label: 'Варианты поиска\'',
          text: 'Вариант поиска №1,Вариант поиска №2'
        }],
      },
    ]
  }),
  methods: {
    open () {
      this.dialog = true
    },
    close () {
      this.dialog = false
    }
  }
}
</script>
