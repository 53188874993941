<template>
  <v-text-field
    v-model="filter[getIndexOfFilter(header.alias)].value[0]"
    class="mt-1"
    dense
    hide-details
    outlined
  >
    <template v-slot:append>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn icon small v-on="on">
            <v-icon small>mdi-filter</v-icon>
          </v-btn>
        </template>
        <v-list v-if="header.predicates.length > 0">
          <v-list-item
            v-for="predicate in header.predicates"
            :style="{backgroundColor: filter[getIndexOfFilter(header.alias)].predicate === predicate ? '#EEEEEE' : 'white'}"
            class="ma-0"
            dense
            @click="filter[getIndexOfFilter(header.alias)].predicate = predicate"
          >
            {{ $t(predicate) }}
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-text-field>
</template>


<script>
import messages from '@/componet-locale/et-filter/messages'

export default {
  name: 'StringHeaderFilter',
  i18n: { messages },
  props: {
    header: Object,
    filter: Array,
    getIndexOfFilter: Function
  }
}
</script>
