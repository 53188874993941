<template>
  <div>
    <component
      :is="filter.type"
      :label="$t(filter.label)"
      :model="filter.model"
      @change="change"
      v-for="filter in filters"/>

    <sorting
      :label="$t(sorting.label)"
      :model="sorting.model"
      @change="change"
      v-if="!displayXS"/>
  </div>
</template>

<script>
import { displayServiceMixin } from '../../mixins/dispay-service-mixin'
import TextFieldFilter from './TextFieldFilter'
import ComboBoxFilter from './ComboBoxFilter'
import Sorting from './Sorting'
import StatusFilter from './StatusFilter'
import messages from '../../componet-locale/filters-panel/messages'

export default {
  name: 'FiltersPanel',
  components: {
    ComboBoxFilter,
    TextFieldFilter,
    Sorting,
    StatusFilter
  },
  mixins: [displayServiceMixin],
  i18n: { messages: messages },
  props: {
    filterList: {
      type: Array,
      default: []
    },
    sorting: {
      type: Object,
      default: {}
    }
  },
  data: () => ({
    showFilters: true
  }),
  watch: {
    windowWidth (width) {
      this.showFilters = width >= 960
    }
  },
  computed: {
    filters () {
      return this.displayXS ? this.filterList.slice(0, 1) : this.filterList
    }
  },
  methods: {
    change () {
      this.$emit('change')
    },
    clearFilters () {
      this.filterList.forEach(f => f.model.value = undefined)
      this.$emit('change')
    }
  }
}
</script>
