<template>
  <v-dialog v-if="dialog" v-model="dialog" width="1100">
    <v-card>
      <v-card-text class="pa-0">
        <v-row>
          <v-col lg="4">
            <v-card-text>
              <abstract-data-edit-form :item="item"/>
            </v-card-text>
          </v-col>
          <v-col md="8" style="height: calc(100vh - 200px); overflow-y: auto;">
            <vue-editor class="mt-6 pr-6" v-model="item.body"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog=false" outlined text>{{ $t('button.close') }}</v-btn>
        <v-spacer/>
        <v-btn @click="save" color="primary" outlined>{{ $t('button.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AbstractDataEditForm from '../utils/AbstractDataEditForm'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'ReportDetailsDialog',
  components: { AbstractDataEditForm, VueEditor },
  data: () => ({
    dialog: false,
    item: {}
  }),
  methods: {
    open () {
      this.dialog = true
      this.item = {}
    },
    save () {
      let item = { ...this.item }
      item.project = this.selectedProject
      this.$axios
        .post('report/save', item)
        .then(() => {
          this.$emit('on-save')
          this.dialog = false
        })
    }
  }
}
</script>
