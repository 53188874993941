<template>
  <feature-card @close="close">
    <template v-slot:name>
      {{ name }}
    </template>
    <template v-slot:subtitle>
      {{ feature.template.name }}
      <br/>
      <span v-if="geometryInfoInfo" v-html="geometryInfoInfo"/>
    </template>
    <template v-slot:info>
      <action-toolbar
        :actions="actions"
        :counters="counters"
        class="px-3"
        @attachData="attachData"
        @calculate="calculate"
        @edit="$emit('edit')"
        @history="history"
        @openIssues="openIssues"
        @remove="openConfirmationDialog"
        @share="share"
      />
      <div class="caption pa-3">
        {{ $t('created') }}: {{ formatDate(feature.creationDate) }}
        <br/>
        {{ $t('lastUpdate') }}: {{ formatDate(feature.modificationDate) }}
      </div>

      <layer-poi-dialog
        ref="layerPoiDlg"
        actions-toolbar
        @update="refresh"
      />
      <layer-poi-changelog-dialog ref="changelogDlg"/>
      <layer-poi-issues-dialog
        ref="issuesDialog"
        @on-save-issue="updateCounter"
      />
      <attached-data ref="attachedDataDlg" @data-updated="updateCounter"/>
      <share-data-dialog ref="shareDataDialog"/>
      <confirmation-dialog
        ref="removeConfirmationDialog"
        :title="$t('deleteConfirmation')"
        @confirm="remove"
      />
    </template>
    <template v-slot:fields>
      <layer-poi-fields
        :poi="feature"
        :readonly-mode="!isAuthenticated"
        @refresh="refresh"
      />
    </template>
    <template
      v-if="isAuthenticated"
      v-slot:actions
    >
      <v-btn
        outlined
        @click="$refs.layerPoiDlg.open(feature)"
      >
        {{ $t('details') }}
      </v-btn>
      <v-spacer/>
      <v-btn
        color="primary"
        outlined
        @click="$emit('save')"
      >
        {{ $t('save') }}
      </v-btn>
    </template>
  </feature-card>
</template>

<script>
import LayerPoiFields from '@/components/layer-poi/LayerPoiFields'
import FeatureCard from '@/components/map/FeatureCard'
import { EventBus } from '@/event-bus'
import LayerPoiDialog from '@/components/layer-poi/LayerPoiDialog'
import { ATTACHED_DATA, EDIT, HISTORY, OPEN_ISSUES, REMOVE, SHARE } from '@/components/map/helpers/map-actions'
import ShareDataDialog from '@/components/utils/ShareDataDialog'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog'
import { getLinkToLayerPoi, getNameOfLayerPoi, saveLayerPoi } from '@/components/layer-poi/layer-poi-service'
import ActionToolbar from '@/components/utils/ActionToolbar'
import LayerPoiChangelogDialog from '@/components/layer-poi/LayerPoiChangelogDialog'
import { mapMeasureMixin } from '@/mixins/map-measure-mixin'
import { Vector } from 'ol/source'
import { GeoJSON } from 'ol/format'
import AttachedData from '@/components/layer-poi/attachments/AttachedData.vue'
import LayerPoiIssuesDialog from '@/components/issue/LayerPoiIssuesDialog.vue'
import { poiMixin } from "@/mixins/poi-mixin"
import messages from '@/componet-locale/layer-poi-feature-card/messages'

export default {
  name: 'LayerPoiFeatureCard',
  i18n: { messages },
  components: {
    LayerPoiIssuesDialog,
    AttachedData,
    LayerPoiChangelogDialog,
    ActionToolbar,
    ConfirmationDialog,
    ShareDataDialog,
    LayerPoiDialog,
    FeatureCard,
    LayerPoiFields
  },
  mixins: [mapMeasureMixin, poiMixin],
  props: {
    feature: Object
  },
  created () {
    this.updateCounter()
  },
  methods: {
    close () {
      this.$emit('close')
    },
    remove () {
      this.$axios
        .delete('layer-poi', {
          params: { id: this.feature.id }
        })
        .then(() => {
          this.refresh()
          this.close()
        })
    },
    save () {
      saveLayerPoi(this.feature)
    },
    refresh () {
      EventBus.$emit('refreshMap')
    },
    openIssues () {
      this.$refs.issuesDialog.open(this.feature)
    },
    attachData () {
      this.$refs.attachedDataDlg.open(this.feature)
    },
    calculate () {
      let source = new Vector({
        features: (
          new GeoJSON({
            featureProjection: 'EPSG:3857'
          }))
          .readFeatures([this.feature])
      })
    },
    share () {
      this.$refs.shareDataDialog.share(
        getLinkToLayerPoi(this.feature)
      )
    },
    history () {
      this.$refs.changelogDlg.open(this.feature)
    },
    openConfirmationDialog () {
      this.$refs.removeConfirmationDialog.open({ name: this.name })
    },
    updateCounter () {
      this.populateCounters(this.feature.id)
    }
  },
  computed: {
    geometryInfoInfo () {
      let geometry = this.getGeometry(this.feature)
      switch (this.feature.geometry.type) {
        case 'LineString' :
        case 'MultiLineString' :
          return 'Длина объекта: ' + this.formatLength(geometry, { projection: 'EPSG:4326' })
        case 'Polygon':
        case 'MultiPolygon' :
          return 'Площадь объекта: ' + this.formatArea(geometry, { projection: 'EPSG:4326' })
      }
    },
    actions () {
      return [OPEN_ISSUES, ATTACHED_DATA, SHARE, HISTORY, EDIT, REMOVE]
        .filter(action => this.isAuthenticated || !action.onlyAuthenticated)
    },
    name () {
      return getNameOfLayerPoi(this.feature)
    }
  }
}
</script>
