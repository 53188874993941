<template>
  <v-menu
    ref="datePickerMenu"
    v-model="datePickerMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :disabled="disabled"
        v-model="date"
        :label="label"
        :outlined="outlined"
        dense
        :hide-details="hideDetails"
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="date"
      no-title
      locale="ru"
      :first-day-of-week="1"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'DateField',
  model: {
    prop: 'value',
    event: 'update'
  },
  props: {
    value: String,
    label: String,
    disabled: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    datePickerMenu: false
  }),
  computed: {
    date: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('update', value)
      }
    }
  }
}
</script>
