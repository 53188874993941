<template>
  <div class="et-viewer">
    <div class="d-flex align-center px-2" style="height: 50px">
      <v-btn outlined @click="openEtAddRowDialog">{{ $t('addRow') }}</v-btn>
    </div>
    <v-data-table
      v-if="filteredHeaders"
      id="et-table"
      :headers="filteredHeaders"
      :items="table.body"
      :loading-text="$t('loading')"
      :no-data-text="$t('noData')"
      :no-results-text="$t('noResults')"
      disable-filtering
      disable-pagination
      disable-sort
      hide-default-footer
      hide-default-header
    >
      <template v-slot:header="{ props }">
        <tr>
          <td v-for="header in filteredHeaders" class="pb-1" style="vertical-align: baseline">
            <div class="d-flex flex-column align-start justify-start flex-grow-1">
              <div
                class="et-table-header-text d-flex v-btn pointer"
                @click="e => setDirection(header, e)"
              >
                <div class="bold">
                  {{ header.fieldName }}
                  <v-icon
                    class="ml-2"
                  >
                    {{ getDirectionIcon(header) }}
                  </v-icon>
                </div>
                <div v-if="sortingConfig.find(el => el.index > 1)">
                  {{ getSortingIndex(header) }}
                </div>
              </div>
              <div v-if="filter.length > 0">
                <component
                  :is="getFilterComponent(header)"
                  :filter="filter"
                  :getIndexOfFilter="getIndexOfFilter"
                  :header="header"
                  :tableId="data.id"
                />
              </div>
            </div>
          </td>
        </tr>
      </template>
      <template v-slot:item="{ item }">
        <tr @click="openEtItemDialog(item)">
          <td v-for="header in filteredHeaders" :style="getStyleFromHeader(header ,data.rules, item[header.alias])">
            <v-icon v-if="header.fieldType  === 'BOOLEAN'">
              {{ item[header.alias] ? 'mdi-check' : 'mdi-close' }}
            </v-icon>
            <div
              v-else
              class="d-flex"
            >
                <span
                  :style="header.relatedWithMap && item[header.lpAlias] ? {
                    color: header.relatedWithMap && item[header.lpAlias] ? 'blue' : 'black',
                    cursor: header.relatedWithMap && item[header.lpAlias] ? 'pointer' : 'default'
                  } : {
                    cursor: 'default'
                  }"
                  @click="e => openLp(e, item, header)"
                >
                  {{ item[header.alias] || '' }}
                </span>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <d-pagination
      :items-per-page-list="etConst.PAGINATION_ITEMS_PER_PAGE"
      :pagination="pagination"
      class="d-pagination"
      @openPage="openPage"
    />
    <et-edit-query-dialog
      v-show="setAbility('SYSTEM_ADMIN')"
      ref="etEditSelectDialog"
      @save="onEditQuery"
    />
    <et-item-dialog
      ref="etItemDialog"
      :primaryTableName="primaryTableName"
      @tableUpdated="updateBody"
    />
    <et-rules-dialog
      ref="etRulesDialog"
      @save="onEditQuery"
    />
    <layer-poi-dialog
      ref="layerPoiDialog"
      actions-toolbar
      map-only-mode
      map-widget
    />
    <et-import-dialog
      ref="etImportDialog"
      @updateTable="updateBody"
    />
    <et-create-dialog
      ref="etCreateDialog"
      @save="onSaveCopy"
    />
    <et-add-row-dialog
      ref="etAddRowDialog"
      :table-id="data.id"
      @save="updateBody"
    />
  </div>
</template>
<script>

import EtEditQueryDialog from '@/components/et/EtEditQueryDialog.vue'
import { EventBus } from '@/event-bus'
import DPagination from '@/components/utils/DPagination.vue'
import messages from '@/componet-locale/et/messages'
import EtItemDialog from '@/components/et/EtItemDialog.vue'
import {
  etConst,
  getDirection,
  getDirectionIcon,
  getFilterFromHeaders,
  getSortingConfigFromHeaders
} from '@/components/et/utils/utlis'
import StringHeaderFilter from '@/components/et/header-filters/StringHeaderFilter.vue'
import BooleanHeaderFilter from '@/components/et/header-filters/BooleanHeaderFilter.vue'
import ListHeaderFilter from '@/components/et/header-filters/ListHeaderFilter.vue'
import EtRulesDialog from '@/components/et/header-rules/EtRulesDialog.vue'
import { getStyleFromHeader } from '@/components/et/header-rules/utils'
import LayerPoiDialog from '@/components/layer-poi/LayerPoiDialog.vue'
import EtImportDialog from '@/components/et/EtImportDialog.vue'
import AbstractDataDetails from '@/components/utils/AbstractDataDetails.vue'
import AbstractDataDetailsDialog from '@/components/utils/AbstractDataDetailsDialog.vue'
import EtCreateDialog from '@/components/et/EtCreateDialog.vue'
import EtAddRowDialog from '@/components/et/EtAddRowDialog.vue'

export default {
  name: 'EtViewer',
  components: {
    EtCreateDialog,
    AbstractDataDetailsDialog,
    AbstractDataDetails,
    EtImportDialog,
    LayerPoiDialog,
    EtRulesDialog,
    EtItemDialog,
    DPagination,
    EtEditQueryDialog,
    EtAddRowDialog
  },
  i18n: { messages },
  props: {
    data: Object,
    splitScreenMode: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    table: Object,
    pagination: { ...etConst.DEFAULT_PAGINATION },
    sortingConfig: [],
    filter: [],
    primaryTableName: null,
    mainPredicate: 'AND',
    lockQuery: false
  }),
  mounted () {
    EventBus.$on('openEtEditQueryDialog', this.openEtEditQueryDialog)
    EventBus.$on('openEtRulesDialog', this.openEtRulesDialog)
    EventBus.$on('openEtImportDialog', this.openEtImportDialog)
    EventBus.$on('exportEt', this.exportXlsx)
    EventBus.$on('makeCopy', this.makeCopy)
    this.init()
  },
  methods: {
    getStyleFromHeader,
    init () {
      this.filter = []
      this.sortingConfig = []
      this.pagination = { ...etConst.DEFAULT_PAGINATION }
      this.mainPredicate = 'AND'
      this.updateBody()
    },
    updateBody () {
      if (this.lockQuery) {
        return
      }
      this.lockQuery = true
      const validPage = this.getValidPage()
      const sortingConfig = getSortingConfigFromHeaders(this.sortingConfig)
      this.$axios
        .post('et/query/select', {
          tableId: this.data.id,
          pageable: {
            page: validPage,
            rowPerPage: this.pagination.itemsPerPage
          },
          mainPredicate: this.mainPredicate,
          sortingConfig: sortingConfig,
          conditions: this.filter
        })
        .then(res => {
          this.table = {
            headers: res.data.headers,
            body: res.data.body
          }
          this.pagination.itemsCount = res.data.itemsCount
          this.pagination.page = validPage
          this.primaryTableName = res.data.primaryTableName
        })
        .catch(e => {
          EventBus.$emit('showErrorMessage', this.$t(e.data.message || 'error'))
        })
        .finally(() => {
          this.lockQuery = false
        })
    },
    exportXlsx () {
      const validPage = this.getValidPage()
      const sortingConfig = getSortingConfigFromHeaders(this.sortingConfig)
      this.$axios
        .post('et/export/xlsx', {
            tableId: this.data.id,
            pageable: {
              page: validPage,
              rowPerPage: this.pagination.itemsPerPage
            },
            mainPredicate: this.mainPredicate,
            sortingConfig: sortingConfig,
            conditions: this.filter
          },
          {
            responseType: 'arraybuffer',
          })
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]))
          let link = document.createElement('a')
          link.href = url
          const name = this.data.name + ' ' + new Date().toLocaleDateString() || 'external-table'
          link.download = `${name}.xlsx`
          document.body.appendChild(link)
          link.click()
        })
        .catch((e) => EventBus.$emit('showErrorMessage', this.$t('exportError')))
    },
    makeCopy () {
      if (!this.$refs.etCreateDialog) return
      let item = JSON.parse(JSON.stringify(this.data))
      item = {
        provider: item.provider,
        description: item.description,
        status: item.status,
        keywords: item.keywords,
        categoryList: item.categoryList,
        qfrom: item.qfrom,
        qwhere: item.qwhere,
        qgroupby: item.qgroupby,
        qorderby: item.qorderby,
        primaryTableName: item.primaryTableName,
        headers: item.headers
      }
      item.headers.forEach(el => delete el.id)
      this.$refs.etCreateDialog.open(item)
    },
    onSaveCopy () {
      EventBus.$emit('etCopySaved')
      this.$router.back()
    },
    getFilterComponent (header) {
      switch (header.fieldType) {
        case 'BOOLEAN':
          return BooleanHeaderFilter
        case 'LIST':
          return ListHeaderFilter
        default:
          return StringHeaderFilter
      }
    },
    onEditQuery () {
      this.$emit('etUpdated')
    },
    openPage (page) {
      this.pagination.page = page
    },
    openEtItemDialog (item) {
      this.$refs.etItemDialog.open(this.data.headers, item, this.data.id)
    },
    openEtEditQueryDialog () {
      if (this.$refs.etEditSelectDialog) {
        this.$refs.etEditSelectDialog.open(this.data)
      }
    },
    openEtRulesDialog () {
      if (this.$refs.etRulesDialog) {
        this.$refs.etRulesDialog.open(this.data)
      }
    },
    openEtImportDialog () {
      if (this.$refs.etImportDialog) {
        this.$refs.etImportDialog.open(this.data)
      }
    },
    getIndexOfFilter (alias) {
      if (this.filter) {
        return this.filter.indexOf(this.filter.find(el => el.alias === alias))
      }
    },
    getValidPage () {
      const pageCount = Math.ceil(this.pagination.itemsCount / this.pagination.itemsPerPage)
      if (this.pagination.page > pageCount) {
        return pageCount === 0 ? 1 : pageCount
      }
      return this.pagination.page
    },
    getDirectionIcon (header) {
      return this.sortingConfig.find(el => el.key === header.key)
        ? getDirectionIcon(this.sortingConfig.find(el => el.key === header.key).direction)
        : ''
    },
    getSortingIndex (header) {
      return this.sortingConfig.find(el => el.key === header.key && el.index > 0)
        ? this.sortingConfig.find(el => el.key === header.key).index
        : ''
    },
    setDirection (header, e) {
      if (!e.shiftKey) {
        this.sortingConfig.forEach((el) => {
          if (el.key === header.key) {
            el.direction = getDirection(el.direction)
            el.index = 1
          } else {
            el.direction = 'NULL'
            el.index = 0
          }
        })
      } else {
        this.sortingConfig.forEach((el) => {
          if (el.key === header.key) {
            el.direction = getDirection(el.direction)
          }
          if (el.key === header.key && el.index === 0) {
            el.index = Math.max(...this.sortingConfig.map(el => el.index)) + 1
          }
        })
      }

    },
    async openLp (e, item, header) {
      if (!header.relatedWithMap) {
        return
      }
      e.stopPropagation()
      const layerPoiId = +item[header.lpAlias]
      if (this.splitScreenMode) {
        EventBus.$emit('openLayerPoi', layerPoiId)
      } else {
        await this.$axios.get('layer-poi/find-by-id', {
          params: { id: layerPoiId }
        })
          .then(({ data }) => {
            this.$refs.layerPoiDialog.open(data)
          })
          .catch(() => EventBus.$emit('showInfoMessage', this.$t('objectNotFound')))
          .finally(() => EventBus.$emit('goToPoiFromEt', { layerPoiId }))
      }
    },
    openEtAddRowDialog () {
      this.$refs.etAddRowDialog.open()
    }
  },
  computed: {
    etConst () {
      return etConst
    },
    filteredHeaders () {
      if (this.table && this.table.headers) {
        return this.table.headers
          .filter(el => !el.excludeFromTable)
          .toSorted((prev, next) => (prev.showIndex - next.showIndex))
      }
    }
  },
  watch: {
    data: {
      handler () {
        this.init()
      },
      deep: true
    },
    filteredHeaders (headers) {
      if (this.sortingConfig.length === 0) {
        this.sortingConfig = getSortingConfigFromHeaders(headers)
      }
      if (this.filter.length === 0) {
        this.filter = getFilterFromHeaders(headers)
      }
    },
    pagination: {
      handler () {
        this.updateBody()
      },
      deep: true
    },
    sortingConfig: {
      handler () {
        this.updateBody()
      },
      deep: true
    },
    filter: {
      handler () {
        this.updateBody()
      },
      deep: true
    },
    mainPredicate () {
      this.updateBody()
    }
  }
}
</script>


<style>

.et-viewer {
  height: 100%;
  position: relative;
}

#et-table {
  height: calc(100% - 96px);
  overflow: auto;
  scrollbar-gutter: stable;
}

#et-table .v-data-table__wrapper {
  height: 100%;
  overflow-y: scroll;
}

.et-table-header-text {
  flex-wrap: nowrap;
  width: fit-content;
}

.et-viewer .d-pagination {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

</style>
