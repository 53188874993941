<template>
  <view-data-page
    :data="data"
    :selectedView="selectedView"
    :show-loader="downloading"
    :split-screen-mode="splitScreenMode"
    :tab-list="tabList"
  >
    <template slot="doc">
      <et-viewer
        v-if="!downloading"
        ref="etViewer"
        :data="data"
        :split-screen-mode="splitScreenMode"
        @etUpdated="init"
      />
    </template>
    <template slot="actions">
      <v-list-item v-if="setAbility('SYSTEM_ADMIN')" dense @click="EventBus.$emit('openEtEditQueryDialog')">
        <v-list-item-avatar>
          <v-icon>
            mdi-database-edit
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t('editTable') }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="setAbility('SYSTEM_ADMIN')" dense @click="EventBus.$emit('openEtRulesDialog')">
        <v-list-item-avatar>
          <v-icon>
            mdi-palette
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t('ruleEditor') }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="setAbility('SYSTEM_ADMIN')" dense @click="EventBus.$emit('openEtImportDialog')">
        <v-list-item-avatar>
          <v-icon>
            mdi-database-import
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{$t('importFromFile')}}
        </v-list-item-content>
      </v-list-item>
      <v-list-item dense @click="EventBus.$emit('exportEt')">
        <v-list-item-avatar>
          <v-icon>mdi-database-export</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t('button.export') }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item dense @click="EventBus.$emit('makeCopy')">
        <v-list-item-avatar>
          <v-icon>mdi-content-copy</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t('makeCopy') }}
        </v-list-item-content>
      </v-list-item>
    </template>
  </view-data-page>
</template>

<script>

import ViewDataPage from '@/components/utils/ViewDataPage.vue'
import EtViewer from '@/components/et/EtViewer.vue'
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/et/messages'

export default {
  name: 'EtView',
  components: { EtViewer, ViewDataPage },
  i18n: { messages },
  props: {
    dataId: Number,
    splitScreenMode: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    data: {
      name: '',
      owner: {
        firstName: ''
      },
      project: {
        name: ''
      }
    },
    selectedView: 'comments',
    tabList: [
      {
        icon: 'message',
        view: 'comments'
      }
    ],
    downloading: true
  }),
  created () {
    this.init()
  },
  methods: {
    init () {
      this.downloading = true
      this.$axios
        .get('data/get', { params: { id: this.dataId || this.id } })
        .then(response => {
          this.data = response.data
        })
        .catch(reason => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
          console.error(reason.data.message)
        })
        .finally(() => {
          this.downloading = false
        })
    }
  },
  computed: {
    EventBus () {
      return EventBus
    },
    id () {
      return this.$route.params.id
    }
  },
  watch: {
    id () {
      this.init()
    }
  }
}
</script>
