<template>
  <v-card outlined>
    <v-list-item>
      <v-list-item-avatar class="align-self-start">
        <user-avatar
          :user="user"
          dense
          online-dot
        />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ getUserFullName(user) }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ getUserInfo(user) }}
          <v-chip-group column light>
            <v-chip v-for="category in userCategories" color="primary" outlined small>
              {{ category.name }}
            </v-chip>
          </v-chip-group>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-card-actions class="pt-0">
      <v-btn
        v-if="setAbility('ADMIN')"
        v-show="user.role === 'USER'"
        icon
        @click="edit"
      >
        <v-icon>mdi-account-edit-outline</v-icon>
      </v-btn>
      <v-spacer/>
      <v-btn
        :disabled="user.id === currentLoginUser.id"
        color="primary"
        outlined
        @click="sendMessage"
      >
        {{ $t('write') }}
        <v-icon right>chat</v-icon>
      </v-btn>
    </v-card-actions>

    <user-project-edit-dialog
      @on-save="init"
      ref="userProjectEditDialog"
      @on-remove="removeUserProject"/>
  </v-card>
</template>

<script>
import UserProjectEditDialog from '@/components/admin/user-management/UserProjectEditDialog'
import UserAvatar from '@/components/utils/UserAvatar'
import { EventBus } from '@/event-bus'
import messages from '../../componet-locale/user-card/messages'

export default {
  name: 'UserCard',
  components: { UserProjectEditDialog, UserAvatar },
  i18n: { messages: messages },
  props: ['user'],
  data: () => ({
    userProject: {}
  }),
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.$axios
        .get('users/get-user-project-by-user', {
          params: {
            userId: this.user.id,
            projectId: this.selectedProject.id
          }
        })
        .then(({ data }) => {
          this.userProject = data
        })
    },
    edit () {
      this.$refs.userProjectEditDialog.editUserProject(this.userProject)
    },
    removeUserProject (id) {
      this.$axios
        .post('project/remove-user-project', null, {
          params: { id: id }
        })
        .then(() => {
          this.$emit('on-remove', id)
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    sendMessage () {
      this.$axios
        .post('chats/direct', null, {
          params: { userId: this.user.id }
        })
        .then(({ data }) => {
          EventBus.$emit('show-chats', data)
          this.$emit('close')
        })
    }
  },
  computed: {
    userCategories () {
      if (this.userProject && this.userProject.categoryList) {
        return this.userProject.categoryList.filter(it => !it.isDefault)
      }
      return []
    }
  }
}
</script>
