<template>
  <v-dialog
      v-model="dialog"
      :fullscreen="displayXS"
      hide-overlay
      max-width="700px"
  >
    <v-card>
      <v-card-title>{{ $t('createChat') }}</v-card-title>

      <v-card-text>
        <v-btn-toggle
            v-model="model.type"
            class="pb-4"
            dense
            mandatory
            rounded
        >
          <v-btn
              v-if="setAbility('ADMIN')"
              value="PUBLIC">
            {{ $t('publicChat') }}
          </v-btn>
          <v-btn value="PRIVATE">{{ $t('privateChat') }}</v-btn>
          <v-btn value="DIRECT">{{ $t('personalMessage') }}</v-btn>
        </v-btn-toggle>
        <div v-if="model.type !== 'DIRECT'">
          <v-text-field
              v-model.trim="$v.model.name.$model"
              :error-messages="chatNameError"
              :label="$t('chatName')"
              dense
              outlined
              @input="unique=true"
          />
          <v-text-field
              v-model="model.description"
              :label="$t('chatDescription')"
              dense
              outlined
          />
          <v-autocomplete
              v-model="model.members"
              :item-text="(item) => (`${item.firstName} ${item.lastName}`)"
              :items="userList"
              :label="$t('chatParticipants')"
              :placeholder="$t('addParticipant')"
              :search-input.sync="searchField"
              chips
              class="py-2"
              deletable-chips
              dense
              hide-no-data
              multiple
              outlined
              return-object
          >
            <template v-slot:selection="{ item }">
              <template>
                <v-chip
                    label
                    outlined
                >
                  <user-avatar
                      :user="item"
                      small
                  />
                  {{ getUserFullName(item) }}
                </v-chip>
              </template>
            </template>
            <template v-slot:item="{ item }">
              <user-avatar
                  :user="item"
                  small
              />
              <v-list-item-content>
                <v-list-item-title v-text="getUserFullName(item)"/>
                <v-list-item-subtitle v-text="getUserInfo(item)"/>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>

        <div v-if="model.type === 'DIRECT'">
          <v-autocomplete
              v-model="selectedUser"
              :item-text="getUserFullName"
              :items="userList"
              :label="$t('recipient')"
              :placeholder="$t('selectRecipient')"
              :search-input.sync="searchField"
              class="mt-3"
              dense
              hide-no-data
              outlined
              return-object>
            <template v-slot:selection="{ item }">
              <template>
                <user-avatar
                    :user="item"
                    small
                />
                {{ getUserFullName(item) }}
              </template>
            </template>
            <template v-slot:item="{ item }">
              <user-avatar
                  :user="item"
                  small
              />
              <v-list-item-content>
                <v-list-item-title v-text="getUserFullName(item)"/>
                <v-list-item-subtitle v-text="getUserInfo(item)"/>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn
            outlined
            @click="dialog=false">{{ $t('button.cancel') }}
        </v-btn>
        <v-spacer/>
        <v-btn
            color="primary"
            outlined
            @click="save">{{ $t('button.create') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import UserAvatar from '@/components/utils/UserAvatar'
import messages from '@/componet-locale/chat-creation-dialog/messages'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

const chatObject = {
  name: '',
  description: '',
  type: 'PUBLIC',
  members: []
}

export default {
  name: 'ChatCreationDialog',
  components: { UserAvatar },
  mixins: [validationMixin, displayServiceMixin],
  data: () => ({
    userList: [],
    unique: true,
    dialog: false,
    model: {},
    selectedUser: {},
    searchField: ''
  }),
  i18n: { messages: messages },
  methods: {
    open () {
      this.$v.$reset()
      this.model = { ...chatObject }
      this.selectedUser = null
      this.dialog = true
      this.find()
    },
    save () {
      this.$v.$touch()
      if (this.$v.$invalid && this.model.type !== 'DIRECT') return
      let url, data, config
      if (this.model.type === 'DIRECT') {
        url = 'direct'
        config = { params: { userId: this.selectedUser.id } }
      } else {
        url = 'new-chat'
        data = this.model
      }

      this.$axios
        .post(`/chats/${url}`, data, config)
        .then(({ data }) => {
          this.dialog = false
          this.$store.dispatch('setActiveChatId', data)
        })
        .catch(() => this.unique = false) // should create error code
    },
    find () {
      this.$axios
        .get('/users/search-user', { params: { str: this.searchField } })
        .then(res => {
          const mergedArr = [...res.data, ...this.model.members]
          this.userList = [...new Set(mergedArr)] // to unique array
        })
    }
  },
  computed: {
    chatNameError () {
      if (!this.$v.model.name.$dirty) { return '' } else if (!this.$v.model.name.required) { return this.$t('chatNameRequired') } else if (!this.$v.model.name.isUnique) { return this.$t('chatNonUnique') }
    }
  },
  watch: {
    searchField (val) {
      if (val && val.length) this.find()
    }
  },
  validations: {
    model: {
      name: {
        required,
        isUnique () {
          return this.unique
        }
      }
    }
  }
}
</script>
