<template>
  <div>
    <v-card-title>
        {{ $t("objectTemplates") }}
      <v-spacer/>
      <v-btn
        outlined
        color="primary"
        @click="create"
      >
        <v-icon>add</v-icon>
        {{ $t("create") }}
      </v-btn>
    </v-card-title>
    <v-list>
        <v-text-field
                v-model="searchField"
                prepend-inner-icon="search"
                solo
                clearable
                hide-details
                :placeholder="$t('searchObject')"
                class="mx-4 mb-2"
        />
      <v-list-item
        v-for="(item, index) in filteredTemplateList"
        :key="index"
        @click="open(item)"
      >
          <v-row class="px-6" align="center">
              <span v-html="highlight(item.name)"></span>
              <v-spacer/>
              <v-icon large>{{getIcon(item.graphType)}}</v-icon>
          </v-row>
      </v-list-item>
    </v-list>
    <poi-template-dialog
      ref="poiTemplateDialog"
      @onSave="loadData"
    />
  </div>
</template>

<script>
import PoiTemplateDialog from '@/components/admin/poi-template-management/PoiTemplateDialog'
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/poi-template-management/messages'

export default {
  name: 'PoiTemplateManagement',
  components: { PoiTemplateDialog },
  data: () => ({
    searchField: '',
    templateList: []
  }),
  mounted () {
    this.loadData()
  },
  i18n: { messages },
  methods: {
    getIcon (type) {
      switch (type) {
        case 'POINT':
          return 'mdi-map-marker-outline'
        case 'POLYGON':
          return 'mdi-pentagon-outline'
        case 'LINE_STRING':
          return 'mdi-chart-timeline-variant'
        case 'MULTI_POLYGON':
          return 'mdi-vector-polygon'
        case 'MULTI_LINE_STRING':
          return 'mdi-chart-multiline'
        default:
          return ''
      }
    },
    loadData () {
      this.$axios
        .get('layer-poi-template')
        .then(response => {
          this.templateList = response.data
        })
        .catch(
          () => { EventBus.$emit('showErrorMessage', this.$t('errorLoadingData')) }
        )
    },
    open (item) {
      this.$refs.poiTemplateDialog.open(item)
    },
    create () {
      this.$refs.poiTemplateDialog.open()
    },
    highlight (name) {
      let re = new RegExp(this.searchField, 'ig')
      return this.searchField ? name.replace(re, `<em>$&</em>`) : name
    }
  },
  computed: {
    filteredTemplateList () {
      if (!this.searchField) return this.templateList
      return this.templateList
        .filter(item =>
          item.name.toLowerCase().indexOf(this.searchField.toLowerCase()) > -1
        )
    }
  }
}
</script>
