<template>
  <div>
    <div v-if="measureType">
      <v-btn
        v-if="measureType === 'Polygon'"
        absolute
        bottom
        class="ma-2"
        right style="z-index: 100" @click="findObjects"
      >
        <v-icon left>search</v-icon>
        {{ $t('findObjects') }}
      </v-btn>
      <vl-layer-vector :z-index="2">
        <vl-source-vector ref="sourceVector" :features.sync="features" ident="draw-target"/>
        <measure-style-box/>
      </vl-layer-vector>

      <vl-interaction-draw :type="measureType" source="draw-target" @drawend="drawend" @drawstart="drawstart">
        <vl-source-vector :features.sync="features" ident="draw-target"/>
        <measure-style-box/>
      </vl-interaction-draw>

      <vl-interaction-modify source="draw-target"/>

      <vl-interaction-snap source="draw-target"/>

      <vl-overlay :offset="[15, 0]" :position="helpTooltip" positioning="center-left">
        <span class="ol-tooltip">{{ $t(helpMsg) }}</span>
      </vl-overlay>

      <!--      <vl-overlay v-if="measureTooltipElement"-->
      <!--                  positioning="bottom-center"-->
      <!--                  :offset="[0, -15]"-->
      <!--                  :position="tooltipCoord">-->
      <!--        <span class="ol-tooltip ol-tooltip-measure" v-html="measureTooltipElement"/>-->
      <!--      </vl-overlay>-->

      <measured-object v-for="feature in features" :feature="feature"/>
    </div>
  </div>
</template>

<script>
import { LineString, Polygon } from 'ol/geom'
import { transform } from 'ol/proj'
import MeasureStyleBox from '@/components/map/helpers/measurer/MeasureStyleBox'
import { unByKey } from 'ol/Observable'
import MeasuredObject from '@/components/map/helpers/measurer/MeasuredObject'
import { mapMeasureMixin } from '@/mixins/map-measure-mixin'
import messages from '../../../../componet-locale/measurer/messages'

export default {
  name: 'Measurer',
  props: ['measureType'],
  components: { MeasuredObject, MeasureStyleBox },
  mixins: [mapMeasureMixin],
  data: () => ({
    features: [],
    helpMsg: '',
    helpTooltip: [0, 0],
    tooltipCoord: [0, 0],
    sketch: null,
    measureTooltipElement: '',
    measureTooltip: null,
    listener: null
  }),
  i18n: { messages: messages },
  mounted () {
    document.addEventListener('keydown', this.keydownListener)
  },
  methods: {
    findObjects () {
      let features = this.$refs.sourceVector.getFeatures()
      this.$emit('findObjects', features)
    },
    keydownListener (e) {
      if (!(e.keyCode === 83 && e.ctrlKey)) {
        return
      }
      e.preventDefault()
      this.search()
    },
    clear () {
      this.features = []
    },
    drawstart (evt) {
      let self = this
      this.sketch = evt.feature
      let tooltipCoord = evt.coordinate

      this.listener = this.sketch.getGeometry().on('change', e => {
        let geom = e.target
        let output
        if (geom instanceof Polygon) {
          output = self.formatArea(geom)
          tooltipCoord = [...geom.getInteriorPoint().getCoordinates()]
          tooltipCoord.pop()
        } else if (geom instanceof LineString) {
          output = self.formatLength(geom)
          tooltipCoord = geom.getLastCoordinate()
        }
        self.measureTooltipElement = output
        self.tooltipCoord = transform(tooltipCoord, 'EPSG:3857', 'EPSG:4326')
      })
    },
    drawend () {
      this.sketch = null
      this.measureTooltipElement = ''
      unByKey(this.listener)
    },
    pointermove (evt) {
      if (evt.dragging) {
        return
      }
      this.helpMsg = 'start'

      if (this.sketch) {
        let geom = this.sketch.getGeometry()
        if (geom instanceof Polygon) {
          this.helpMsg = 'set'
        } else if (geom instanceof LineString) {
          this.helpMsg = 'change'
        }
      }
      this.helpTooltip = evt.coordinate
    }
  }
}
</script>
