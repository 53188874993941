<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      width="600"
      style="z-index: 1001"
    >
      <v-card v-if="dialog">
        <v-card-title class="title pb-0">
          {{ name }}
          <v-spacer/>
          <v-btn v-if="readonlyMode || mapOnlyMode" icon @click="close">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-0">
          <div class="caption py-3">
            {{ $t('created') }}: {{ formatDate(poi.creationDate) }}
            <br/>
            {{ $t('lastUpdate') }}: {{ formatDate(poi.modificationDate) }}
          </div>
          <layer-poi-map-widget
            :poi="poi"
            class="mb-3"
          />
          <action-toolbar
            v-if="actionsToolbar"
            :actions="actions"
            :counters="counters"
            @attachData="attachData"
            @directToMap="directToMap"
            @history="openHistory"
            @openIssues="openIssues"
            @printTemplate="openPrintTemplateSelectorDialog"
            @remove="openConfirmationDialog"
            @share="share"
          />
          <layer-poi-fields
            v-if="!mapOnlyMode"
            :poi="poi"
            :readonlyMode="readonlyMode || !isAuthenticated"
            class="mt-7"
          />
        </v-card-text>
        <v-card-actions v-if="!readonlyMode && !mapOnlyMode">
          <v-btn
            outlined
            @click="close"
          >
            {{ $t('button.cancel') }}
          </v-btn>
          <v-spacer/>
          <v-btn
            v-if="!readonlyMode && isAuthenticated"
            color="primary"
            outlined
            @click="save"
          >
            <v-icon left>save</v-icon>
            {{ $t('button.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <layer-poi-issues-dialog
      ref="issuesDialog"
      @on-save-issue="updateIssues"
    />
    <confirmation-dialog
      ref="removeConfirmationDialog"
      @confirm="remove"
    />
    <share-data-dialog ref="shareDataDialog"/>
    <layer-poi-change-log-dialog ref="changelogDlg"/>
    <attached-data ref="attachedDataDlg"
                   @on-update="updateCounters"
    />
    <print-template-selector-dialog
      ref="printTemplateSelector"
      @openPrintTemplateDialog="openPrintTemplateDialog"
      @print="print"
    />
    <print-template-dialog
      ref="printTemplateDialog"
    />
  </div>
</template>

<script>
import LayerPoiFields from '@/components/layer-poi/LayerPoiFields'
import LayerPoiMapWidget from '@/components/layer-poi/LayerPoiMapWidget'
import { EventBus } from '@/event-bus'
import {
  ATTACHED_DATA,
  DIRECT_TO_MAP,
  HISTORY,
  OPEN_ISSUES,
  PRINT_TEMPLATE,
  REMOVE,
  SHARE,
  SPACER
} from '@/components/map/helpers/map-actions'
import {
  getLinkToLayerPoi,
  getMapListByLayerPoi,
  getNameOfLayerPoi,
  saveLayerPoi
} from '@/components/layer-poi/layer-poi-service'
import ShareDataDialog from '@/components/utils/ShareDataDialog'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog'
import ActionToolbar from '@/components/utils/ActionToolbar'
import AttachedData from '@/components/layer-poi/attachments/AttachedData.vue'
import LayerPoiChangeLogDialog from '@/components/layer-poi/LayerPoiChangelogDialog'
import LayerPoiIssuesDialog from '@/components/issue/LayerPoiIssuesDialog.vue'
import { poiMixin } from '@/mixins/poi-mixin'
import messages from '@/componet-locale/layer-poi-dialog/messages'
import PrintTemplateSelectorDialog from '@/components/admin/print-template/PrintTemplateSelectorDialog.vue'
import PrintTemplateDialog from '@/components/admin/print-template/PrintTemplateDialog.vue'

export default {
  name: 'LayerPoiDialog',
  i18n: { messages },
  components: {
    PrintTemplateDialog,
    PrintTemplateSelectorDialog,
    LayerPoiChangeLogDialog,
    AttachedData,
    ActionToolbar,
    ConfirmationDialog,
    ShareDataDialog,
    LayerPoiMapWidget,
    LayerPoiFields,
    LayerPoiIssuesDialog
  },
  props: {
    mapWidget: {
      type: Boolean,
      default: false
    },
    actionsToolbar: {
      type: Boolean,
      default: false
    },
    readonlyMode: {
      type: Boolean,
      default: false
    },
    mapOnlyMode: {
      type: Boolean,
      default: false
    }
  },
  mixins: [poiMixin],
  data: () => ({
    dialog: false,
    poi: null,
    selectedTemplateId: null
  }),
  created () {
    if (this.mapWidget) {
      this.actions.push(SPACER, DIRECT_TO_MAP)
    }
  },
  methods: {
    open (poi) {
      this.poi = { ...poi }
      this.populateCounters(poi.id)
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    directToMap (event) {
      getMapListByLayerPoi(
        this.poi,
        response => {
          this.openInNewTab(
            response.data[0],
            event,
            { layerPoiId: this.poi.id }
          )
        }, () => EventBus.$emit('showErrorMessage', this.$t('mapsNotFound'))
      )
    },
    getProperties () {
      let map = new Map()

      function getValue (prop) {
        switch (prop.field.type) {
          case 'STRING':
            return prop.string
          case 'DATE':
            return prop.date
          case 'NUMBER':
            return prop.number
          case 'BOOLEAN':
            return prop.boolean
          default:
            return prop.value
        }
      }

      this.poi.properties.forEach(prop => map.set(prop.field.name, getValue(prop)))
      return map
    },
    print () {
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')

      let rows = []
      this.getProperties().forEach((value, key) => {
        rows.push(`<div><b>${key}: </b>${value}</div>`)
      })
      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
            <style>
                div {padding: 10px}
            </style>
        </head>
        <body>
          ${rows.join('')}
        </body>
      </html>`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    },
    share () {
      this.$refs.shareDataDialog.share(
        getLinkToLayerPoi(this.poi, this.selectedProject)
      )
    },
    openIssues () {
      this.$refs.issuesDialog.open(this.poi)
    },
    attachData () {
      this.$refs.attachedDataDlg.open(this.poi)
    },
    remove () {
      this.$axios
        .delete('layer-poi', {
          params: { id: this.poi.id }
        })
        .then(() => {
          EventBus.$emit('showInfoSuccessMessage', this.$t('objectDeleted'))
          this.$emit('update')
          this.close()
        })
        .catch(() => {
          EventBus.$emit('showErrorSuccessMessage', this.$t('error'))
        })
    },
    openHistory () {
      this.$refs.changelogDlg.open(this.poi)
    },
    openConfirmationDialog () {
      this.$refs.removeConfirmationDialog.open(
        { name: this.name }
      )
    },
    save () {
      saveLayerPoi(
        this.poi,
        () => {
          EventBus.$emit('showSuccessMessage', this.$t('changesSaved'))
          this.$emit('update')
        },
        () => EventBus.$emit('showErrorMessage', this.$t('error'))
      )
    },
    updateCounters () {
      this.populateCounters(this.poi.id)
    },
    updateIssues () {
      this.$emit('on-update')
      this.updateCounters()
    },
    openPrintTemplateSelectorDialog () {
      this.$refs.printTemplateSelector.open(this.poi.template.printTemplates)
    },
    openPrintTemplateDialog (id) {
      this.$refs.printTemplateDialog.open(id, { pois: [this.poi] })
    }
  },
  computed: {
    name () {
      return getNameOfLayerPoi(this.poi)
    },
    actions () {
      return [OPEN_ISSUES, ATTACHED_DATA, SHARE, HISTORY, PRINT_TEMPLATE, REMOVE]
        .filter(el => (!el.onlyAuthenticated || this.isAuthenticated))
    }
  }
}
</script>
