<template>
  <v-dialog
    v-model="dialog"
    scrollable
    width="700"
  >
    <v-card height="800">
      <v-card-title class="px-5">
        <v-text-field
          v-model="searchField"
          :full-width="false"
          :placeholder="$t('searchFieldPlaceholder')"
          clearable
          hide-details
          prepend-inner-icon="people_alt"
          @click:clear="searchField=''"
          append-icon="info"
          @click:append="$refs.userByCompanyDialog.open()"
          solo
        />
      </v-card-title>
      <v-card-text class="py-0">
        <div
          v-for="(listOfUser, company) in usersByCompany"
        >
          <span class="subtitle-1">{{ company === 'null' ? 'Другие пользователи' : company }}</span>
          <v-chip
            small
            label
            outlined
            class="ml-2"
          >
            {{ listOfUser.length }}
          </v-chip>
          <v-layout
            row wrap
            class="mb-3"
          >
            <v-flex
              v-for="user in listOfUser"
              :key="user.id"
              sm6
              xs12
            >
              <user-card
                :user="user"
                class="ma-2"
                @close="dialog=false"
                @on-remove="removeUserProject"
              />
            </v-flex>
          </v-layout>
        </div>
      </v-card-text>
    </v-card>

    <users-by-company-dialog
      ref="userByCompanyDialog"
      :users-by-company="usersByCompany"
    />
  </v-dialog>
</template>

<script>
import UserCard from '@/components/utils/UserCard'
import messages from '../../componet-locale/user-list-dialog/messages'
import UsersByCompanyDialog from '@/components/utils/UsersByCompanyDialog'

export default {
  name: 'UserListDialog',
  i18n: { messages: messages },
  components: { UsersByCompanyDialog, UserCard },
  props: {
    userList: {
      type: Array,
      default: []
    }
  },
  data: () => ({
    dialog: false,
    searchField: ''
  }),
  computed: {
    usersByCompany () {
      let groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x)
          return rv
        }, {})
      }

      let userList = this.userList
        .filter(item => {
          let company = item.company || ''
          return this.getUserFullName(item).toLowerCase().indexOf(this.searchField.toLowerCase()) > -1 || company.toLowerCase().indexOf(this.searchField.toLowerCase()) > -1
        }
        )
        .sort((first, second) => {
          if (first.firstName > second.firstName) return 1
          if (second.firstName > first.firstName) return -1
        })
      let unordered = groupBy(userList, 'company')
      return Object.keys(unordered).sort().reduce(
        (obj, key) => {
          obj[key] = unordered[key]
          return obj
        },
        {}
      )
    }
  },
  methods: {
    removeUserProject (id) {
      this.userList = this.userList.filter(function (obj) {
        return obj.id !== id
      })
      this.userList.slice()
    },
    open () {
      this.dialog = true
    }
  }
}
</script>
