<template>
  <v-card-text>
    <v-textarea v-model="item.qfrom" auto-grow dense label="FROM" outlined row-height="12"/>
    <v-text-field v-model="item.qwhere" dense label="WHERE" outlined/>
    <v-text-field v-model="item.qgroupby" dense label="GROUP BY" outlined/>
    <v-text-field v-model="item.qorderby" dense label="ORDER BY" outlined/>
    <v-text-field v-model="item.primaryTableName" dense label="PRIMARY TABLE NAME" outlined/>
    <et-header-editor ref="etHeaderEditor" :item="item"/>

    <select-query-preview ref="selectQueryPreview"/>
  </v-card-text>
</template>

<script>
import EtHeaderEditor from '@/components/et/EtHeaderEditor.vue'
import SelectQueryPreview from '@/components/et/SelectQueryPreview.vue'
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/et-edit/messages'

export default {
  name: "EtQueryEditor",
  i18n: { messages },
  components: { SelectQueryPreview, EtHeaderEditor },
  props: {
    item: Object
  },
  methods: {
    save () {
      if (this.isTableFormValid() && this.$refs.etHeaderEditor.checkHeadersValid()) {
        this.item.project = this.selectedProject
        this.item.headers = this.item.headers.map((el, i) => ({ ...el, showIndex: i }))
        this.$axios.post('et/save', this.item)
          .then(() => {
            EventBus.$emit('showSuccessMessage', this.$t('tableSaved'))
            this.$emit('save')
          })
          .catch((e) => {
            console.error(e)
            EventBus.$emit('showErrorMessage', this.$t('errorChangingTable'))
          })
      }
    },
    isTableFormValid () {
      if (!this.item.qfrom || this.item.qfrom.trim().length === 0) {
        EventBus.$emit('showErrorMessage', this.$t('emptyFromBlock'))
        return false
      }
      return true
    },
    showQueryExample () {
      if (this.isTableFormValid() && this.$refs.etHeaderEditor.checkHeadersValid()) {
        const item = { ...this.item }
        item.project = this.selectedProject
        item.headers = this.item.headers.map((el, i) => ({ ...el, showIndex: i }))
        this.$axios.post('et/query/get-select-form', item)
          .then(res => {
            this.$refs.selectQueryPreview.open(res.data)
          })
          .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
      }
    }
  }
}
</script>
