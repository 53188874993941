<template>
  <v-container v-if="category" grid-list-md>
    <v-layout align-center justify-center row wrap>
      <v-flex lg10 md12 sm12 xl8 xs12>
        <v-card class="elevation-0">
          <v-breadcrumbs v-if="!displayXS" :items="breadcrumbs"/>

          <v-card-title v-else class="pa-0">
            <v-btn
              :to="linkBack"
              class="mr-3"
              icon
              large
            >
              <v-icon>arrow_back</v-icon>
            </v-btn>
            {{ selectedProject.name }}
          </v-card-title>

          <v-card :style="background" class="mt-3" dark flat>
            <v-card-title class="pb-0">
              {{ category.name }}
              <v-spacer/>
              <v-btn v-if="setAbility('ADMIN')"
                     icon
                     @click="$refs.confirmationDialog.open(category)">
                <v-icon color="white">
                  delete
                </v-icon>
              </v-btn>
              <v-btn
                v-if="setAbility('ADMIN')"
                icon
                @click="$refs.copyCategoryDialog.open(category)">
                <v-icon color="white">
                  content_copy
                </v-icon>
              </v-btn>
              <v-btn
                v-if="setAbility('ADMIN')"
                icon
                @click="$refs.categoryDetailsDialog.edit(category)">
                <v-icon color="white">
                  edit
                </v-icon>
              </v-btn>
            </v-card-title>

            <div class="mx-4"><strong>{{ category.type }}</strong></div>

            <v-card-text
              v-if="category.description"
              class="white--text"
              v-html="category.description"/>

            <v-card-actions class="px-4 pb-3">
              <v-spacer/>
              <v-btn text @click="$refs.userListDialog.open()">
                <v-icon left>people_alt</v-icon>
                {{ userList.length }}
              </v-btn>
            </v-card-actions>
            <user-list-dialog
              ref="userListDialog"
              :user-list="userList"
            />
          </v-card>

          <div v-if="filters && filters.length > 0" class="mt-2">
            <v-icon color="blue darken-2">tag</v-icon>
            <v-chip
              v-for="filter in filters"
              :outlined="!filter.active"
              class="ml-2 my-1"
              label
              @click="filter.active = !filter.active">
              {{ filter.value }}
            </v-chip>
          </div>

        </v-card>
        <div v-if="category.hasProcess">
          <div class="mt-5">Процесс загрузки данных</div>
          <v-card-actions class="pa-0">
            <v-progress-linear
              :value="progress"
              dark
              height="25"
            >
              <strong>{{ progress }}%</strong>
            </v-progress-linear>

            <v-menu>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>info</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <div v-for="(value, key) in dataByStatus">
                    <b>{{ $t(`adStatus.${key}`) }}</b>: {{ value.length }}
                  </div>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-card-actions>
        </div>
      </v-flex>

      <v-flex
        v-for="item in filteredDataList"
        :key="item.id" lg10 md12
        sm12 xl8
        xs12
      >
        <simple-data-row
          :item="item"
          :showStatus="category.hasProcess"
          simple
          @open="$refs.viewDialog.open"
        />
      </v-flex>
    </v-layout>

    <category-details-dialog
      ref="categoryDetailsDialog"
      @onSave="onSave"
    />
    <copy-category-dialog ref="copyCategoryDialog"/>
    <confirmation-dialog
      ref="confirmationDialog"
      :title="$t('removeConfirmation')"
      @confirm="removeCategory"
    />

    <abstract-data-view-dialog ref="viewDialog"/>
  </v-container>

</template>

<script>
import SimpleDataRow from '../utils/SimpleDataRow'
import CategoryDetailsDialog from './CategoryDetailsDialog'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import ConfirmationDialog from '../utils/ConfirmationDialog'
import messages from '../../componet-locale/category-view/messages'
import UserListDialog from '@/components/utils/UserListDialog'
import AbstractDataViewDialog from '@/components/utils/AbstractDataViewDialog'
import CopyCategoryDialog from '@/components/category/CopyCategoryDialog'

export default {
  name: 'CategoryView',
  components: {
    CopyCategoryDialog,
    AbstractDataViewDialog,
    UserListDialog,
    CategoryDetailsDialog,
    SimpleDataRow,
    ConfirmationDialog
  },
  i18n: { messages: messages },
  mixins: [displayServiceMixin],
  data: () => ({
    searchField: '',
    datasetList: [],
    category: null,
    dataList: [],
    keywords: [],
    userList: [],
    filters: [],
    dataByStatus: {}
  }),
  methods: {
    loadDataList () {
      this.$axios
        .get('data/get-all-for-category', {
          params: { id: this.category.id }
        })
        .then(response => {
          this.dataList = response.data
          this.addFilterParams()

          let groupBy = function (xs, key) {
            return xs.reduce(function (rv, x) {
              (rv[x[key]] = rv[x[key]] || []).push(x)
              return rv
            }, {})
          }
          this.dataByStatus = groupBy(this.dataList, 'status')
        })
    },
    loadData () {
      this.userList = []
      let id = this.$route.params.id
      this.$axios
        .get('category/get', { params: { id: id } })
        .then(response => {
          this.category = response.data
          this.loadDataList()
        })
      this.$axios
        .get('users/find-by-category', { params: { dataCategoryId: id } })
        .then(response => {
          this.userList = this.userList.concat(response.data)
        })
      this.$axios
        .get('users/get-admin-list-by-project', { params: { projectId: this.selectedProject.id } })
        .then(response => {
          this.userList = this.userList.concat(response.data)
        })
    },
    addFilterParams () {
      let map = new Map()
      this.filters = []
      this.dataList.forEach(data => {
        data.keywords.forEach(keyword => {
          if (!map.has(keyword.id)) {
            map.set(keyword.id, keyword)
            keyword.active = false
            this.filters.push({ ...keyword })
          }
        })
      })
    },
    onSave () {
      this.loadData()
    },
    removeCategory () {
      this.$axios
        .post('category/delete', null, {
          params: { id: this.category.id }
        })
        .then(() => this.$router.push(this.linkBack))
    }
  },
  computed: {
    background () {
      if (!this.category) return
      let hash = 0
      for (let i = 0; i < this.category.name.length; i++) {
        hash = this.category.name.charCodeAt(i) + ((hash << 5) - hash)
        hash = hash&hash
      }
      hash = Math.abs(hash)
      const leftColor = hash % 360
      const rightColor = hash + 50
      return {
        'background-image': `linear-gradient(to bottom right, hsla(${leftColor}, 70%, 50%, 0.5), hsl(${rightColor}, 70%, 50%))`
      }
    },
    activeFilters () {
      return this.filters.filter(it => it.active).map(it => it.id)
    },
    filteredDataList () {
      if (this.activeFilters.length) {
        return this.dataList.filter(it => it.keywords.some(it => this.activeFilters.includes(it.id)))
      } else {
        return this.dataList
      }
    },
    selectedProject () {
      return this.$store.getters.selectedProject
    },
    linkBack () {
      return `/project/${this.selectedProject.id}/home`
    },
    breadcrumbs () {
      return [
        {
          text: this.selectedProject.name,
          to: this.linkBack
        },
        {
          text: this.category.name,
          disabled: true
        }
      ]
    },
    progress () {
      if (this.dataByStatus['CHECKED'] && this.dataList) {
        return (this.dataByStatus['CHECKED'].length / this.dataList.length * 100).toFixed(1)
      }
      return 0
    }
  },
  created () {
    this.loadData()
  }
}
</script>
