<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title
        class="headline lighten-2 pb-0"
        primary-title>
        {{ $t('widget') }}
      </v-card-title>
      <v-card-text>
        <v-select
          v-model="widget.type"
          :items="widgetTypes"
          :label="$t('widgetType')"
          @change="find"
        >
          <template v-slot:item="{item}">
            {{ $t(item) }}
          </template>
          <template v-slot:selection="{item}">
            {{ $t(item) }}
          </template>
        </v-select>

        <v-autocomplete
          v-if="widget.type === 'MAP' || widget.type === 'CHART' || widget.type === 'TABLE'"
          v-model="widget.abstractData"
          :items="items"
          :label="$t('source')"
          :no-data-text="$t('noMatch')"
          :search-input.sync="search"
          clearable
          error-messages=""
          item-text="name"
          return-object
          @click:clear="search = ''"
        />
        <v-text-field
          v-if="widget.type === 'TEXT'"
          v-model="widget.text"
        />

      </v-card-text>
      <v-card-actions>
        <v-btn outlined @click="dialog=false">
          {{ $t('button.cancel') }}
        </v-btn>
        <v-spacer/>
        <v-btn :disabled="disableCondition" color="primary" outlined @click="save">
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { WIDGET_TYPES, WIDGETS } from '@/services/widget-service'
import messages from '../../componet-locale/widget-details-dialog/messages'

const emptyWidget = {
  position: {},
  name: '',
  type: 'MAP'
}

export default {
  name: 'WidgetDetailsDialog',
  data: () => ({
    dialog: false,
    widget: {},
    items: [],
    search: '',
    widgets: WIDGETS,
    widgetTypes: WIDGET_TYPES
  }),
  i18n: { messages: messages },
  methods: {
    open (widget = null) {
      this.widget = { ...widget } || { ...emptyWidget }
      this.dialog = true
      this.find()
    },
    save () {
      let widget = this.widgets[this.widget.type]
      this.widget.w = widget.minW
      this.widget.h = widget.minH
      this.widget.project = this.selectedProject
      this.$axios
        .post('/widget/save', this.widget)
        .then(() => {
          this.dialog = false
          this.$emit('onSave')
        })
    },
    find () {
      if (!this.isAdWidget) {
        return
      }
      const searchVal = this.search || ''
      this.$axios
        .get('/data/search-by-type', {
          params: {
            str: searchVal,
            projectId: this.selectedProject.id,
            dataType: this.widget.type
          }
        })
        .then(response => this.items = response.data)
    }
  },
  computed: {
    disableCondition () {
      if (!this.widget) {
        return true
      }
      switch (this.widget.type) {
        case WIDGETS.MAP.title:
          return !this.widget.abstractData
        case WIDGETS.CHART.title:
          return !this.widget.abstractData
        case WIDGETS.TABLE.title:
          return !this.widget.abstractData
        case WIDGETS.TEXT.title:
          return false
        default:
          return false
      }
    },
    isAdWidget () {
      switch (this.widget.type) {
        case WIDGETS.MAP.title:
          return true
        case WIDGETS.CHART.title:
          return true
        case WIDGETS.TABLE.title:
          return true
        case WIDGETS.TEXT.title:
          return false
        default:
          return false
      }
    }
  },
  watch: {
    search () {
      this.find()
    },
    'widget.type' () {
      this.$set(this.widget, 'abstractData', null)
    }
  }
}
</script>
