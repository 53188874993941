<template>
  <v-checkbox
    v-model="item[header.alias]"
    :label="header.fieldName"
    :readonly="readonly"
    class="mt-0 mb-4"
    dense
    hide-details
  />
</template>

<script>
export default {
  name: 'StringEtItem',
  props: {
    item: Object,
    header: Object,
    editable: Boolean
  },
  computed: {
    readonly () {
      return this.header.isPrimaryTableKey
        || this.header.isComputedField
        || !this.editable
    }
  }
}
</script>
