<template>
  <span v-if="!onlyContainer" :class="dense ? 'ma-0' : 'mr-2'">
    <v-badge
      :value="online"
      bottom
      color="green accent-4"
      dot
      offset-x="10"
      offset-y="10"
    >
      <v-avatar
        :size="size"
        :style="avatarBackground"
        class="pointer"
      >
    <span
      v-show="!xSmall"
      :style="small ? 'font-size: 10px' : ''"
      class="white--text font-weight-bold"
    >
      {{ avatarName }}
    </span>
      </v-avatar>
    </v-badge>
  </span>
  <div v-else :style="{width: `${size}px`}" class="mr-2"/>
</template>

<script>
export default {
  name: 'UserAvatar',
  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    xSmall: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    onlineDot: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    onlyContainer: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    online () {
      if (this.onlineDot) return this.$store.getters.onlineUsers.includes(this.user.id)
      return false
    },
    size () {
      if (this.small) return 25
      return this.xSmall ? 12 : 38
    },
    username () {
      const { firstName, lastName } = this.user
      return `${firstName} ${lastName}`
    },
    avatarBackground () {
      let hash = 0
      for (let i = 0; i < this.username.length; i++) {
        hash = this.username.charCodeAt(i) + ((hash << 5) - hash)
        hash = hash&hash
      }
      hash = Math.abs(hash)
      const leftColor = hash % 360
      const rightColor = hash + 50
      return { 'background-image': `linear-gradient(to bottom right, hsla(${leftColor}, 70%, 50%, 0.5), hsl(${rightColor}, 70%, 50%))` }
    },
    avatarName () {
      const { firstName, lastName } = this.user
      let avatarName = '';
      if (firstName && firstName.trim().length > 0) {
        avatarName = avatarName.concat(firstName.trim()[0])
      }
      if (lastName && lastName.trim().length > 0) {
        avatarName = avatarName.concat(lastName.trim()[0])
      }
      return `${avatarName.length > 0 ? avatarName : "U"}`
    }
  }
}
</script>
