<template>
  <v-dialog v-if="dialog" v-model="dialog" persistent width="900">
    <v-card class="overflow-x-hidden" height="90vh">
      <v-card-title class="pb-0">
        {{ $t('ruleEditor') }}
      </v-card-title>
      <v-card-text class="overflow-auto" style="height: calc(90vh - 110px)">
        <div v-if="data.rules.length>0">
          <v-expansion-panels
            accordion
            multiple
          >
            <vuedraggable v-model="data.rules" handle=".handle" style="width: 100%">
              <header-rule
                v-for="(rule, index) in data.rules"
                :key="index"
                :headers="data.headers"
                :rule="rule"
                @remove="remove(index)"
              />
            </vuedraggable>
          </v-expansion-panels>
        </div>
        <div v-else>
          {{ $t('noRules') }}
        </div>
        <div class="d-flex mt-4">
          <v-spacer/>
          <v-btn outlined @click="addRule">{{ $t('button.add') }}</v-btn>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined text @click="close">{{ $t('button.close') }}</v-btn>
        <v-spacer/>
        <v-btn color="primary" outlined @click="save">{{ $t('button.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import HeaderRule from '@/components/et/header-rules/HeaderRule.vue'
import Vuedraggable from 'vuedraggable'
import { DEFAULT_COLORS } from '@/components/et/header-rules/utils'
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/et-rules/messages'

export default {
  name: 'EtRulesDialog',
  components: { Vuedraggable, HeaderRule },
  i18n: { messages },
  data: () => ({
    dialog: false,
    data: null
  }),
  methods: {
    open (data) {
      this.dialog = true
      this.data = JSON.parse(JSON.stringify(data))
    },
    close () {
      this.data = null
      this.dialog = false
    },
    addRule () {
      this.data.rules.push({
        headers: [],
        backgroundMode: true,
        textColorMode: false,
        backgroundColor: DEFAULT_COLORS.BACKGROUND_COLOR,
        textColor: DEFAULT_COLORS.TEXT_COLOR
      })
    },
    remove (index) {
      this.data.rules.splice(index, 1)
    },
    save () {
      this.$axios.post('et/save', this.data)
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('rulesChanged'))
          this.$emit('save')
          this.close()
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('errorChangingRules')))
    }
  }
}
</script>
