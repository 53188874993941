<template>
  <div id="map-search-slide-bar">
    <v-text-field
      ref="search-field"
      v-model="filter.searchField"
      :background-color="showResults ? 'grey lighten-3' : ''"
      :flat="showResults"
      :loading="loading"
      :placeholder="$t('search')"
      clearable
      dense
      hide-details
      solo
      style="position: absolute;left: 8px;top:10px;z-index: 7; width: 375px"
      @focus="expand"
      @click:clear="clear"
      @keyup.enter="search"
    >
      <template
        v-slot:append>
        <v-icon :color="tune ? 'primary' : ''" class="mr-2" @click="tune = showResults ? !tune : true">tune</v-icon>
        <v-hover v-slot="{hover}">
          <v-icon :color="hover ? 'primary' : ''" @click="search">search</v-icon>
        </v-hover>
      </template>
      <template v-slot:append-outer>
        <v-tooltip open-delay="700" right>
          <template v-slot:activator="{on}">
            <v-hover v-slot="{ hover }">
              <div
                class="elevation-1"
                style="background-color: #4c4c4c; margin-top: -3px; margin-left:10px; height: 30px; border-radius: 3px; display: flex; align-items: center; cursor: pointer"
                @click="showResults ? collapse() : expand()"
                v-on="on"
              >
                <v-icon :color="hover ? 'white' : 'grey lighten-1'"
                        :style="{transform: showResults ? 'rotate(180deg)' : ''}">
                  {{ 'mdi-menu-right' }}
                </v-icon>
              </div>
            </v-hover>
          </template>
          {{ showResults ? $t('collapse') : $t('expand') }}
        </v-tooltip>
      </template>
    </v-text-field>


    <Transition name="slide-left">
      <div
        v-show="showResults"
        id="map-search-slide-bar-container"
        class="elevation-1">
        <div style="height: 55px"/>
        <div style="overflow-y: scroll; height: calc(100% - 55px)">
          <map-search-filter
            v-if="tune"
            v-model="filter"
            :layers="layers"
            @search="search"
          />
          <layer-poi-search-result
            v-for="item in layerPoiList"
            :item="item"
            :search-field="filter.searchField"
            @show="show"
          />
          <zws-search-result
            v-for="item in entries"
            :item="item"
          />
          <address-search-result
            :addressList="addressList"
            @show="showBbox"
          />
        </div>
      </div>
    </Transition>


    <vl-layer-vector
      v-if="addressList.length"
      :z-index="6"
    >
      <vl-source-vector :features="addressList"/>
      <vl-style-box>
        <vl-style-icon
          :anchor="[0.5, 0.4]"
          :scale="0.2"
          src="/image/baseline_location.png"
        />
        <vl-style-stroke
          :width="5"
          color="red"
        />
      </vl-style-box>
    </vl-layer-vector>
  </div>
</template>

<script>
import MapSearchMixin from '@/components/map/map-search/MapSearchMixin'


export default {
  name: "MapSearchSlideBar",
  mixins: [MapSearchMixin],
}
</script>


<style scoped>
#map-search-slide-bar-container {
  position: absolute;
  height: 100%;
  z-index: 6;
  width: 350px;
  background-color: white;
}

#map-search-slide-bar .slide-left-enter-active,
#map-search-slide-bar .slide-left-leave-active {
  transition: all 0.3s ease-out;
}

#map-search-slide-bar .slide-left-enter,
#map-search-slide-bar .slide-left-leave-to {
  transform: translateX(-500px);
  opacity: 0;
}

</style>
