<template>
  <div style="height: 100%; overflow-y: auto">
    <vue-editor
      v-show="false"
    />
    <v-hover v-slot="{ hover }">
      <div>
        <div
          class="ql-editor"
          style="height: 100%; overflow-y: auto"
          v-html="widget.text"
        />
        <v-fade-transition>
          <div
            v-show="editable && hover"
            class="text-widget-settings-layout"
          >
            <v-btn icon style="background-color: white" @click="openTextWidgetEditDialog">
              <v-icon>settings</v-icon>
            </v-btn>
          </div>
        </v-fade-transition>
      </div>
    </v-hover>
    <text-widget-edit-dialog ref="textWidgetEditDialog" :widget="widget"/>
  </div>
</template>

<script>

import { VueEditor } from 'vue2-editor/dist/vue2-editor.umd'
import TextWidgetEditDialog from '@/components/widget/TextWidgetEditDialog.vue'

export default {
  name: "TextWidget",
  components: { TextWidgetEditDialog, VueEditor },
  props: {
    widget: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openTextWidgetEditDialog () {
      this.$refs.textWidgetEditDialog.open()
    }
  }
}

</script>

<style scoped>
.text-widget-settings-layout {
  position: absolute;
  border-radius: 5px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(1, 1, 1, 0.2)
}
</style>
