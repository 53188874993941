<template>
  <div>
    <div class="header-data-panel" v-show="!displayXS || isDataListView">

      <div class="float-right" style="padding-top: 2px">
        <v-btn @click="create" color="primary" outlined>
          {{ $t('button.create') }}
        </v-btn>
      </div>

      <filters-panel :filter-list="filters" :sorting="sorting" @change="filterData" v-if="!displayXS"/>

      <v-card-actions v-if="displayXS">
        <v-btn @click="$router.push(`/project/${selectedProject.id}`)" class="px-1 mb-1 d-inline-block" icon large>
          <v-icon>navigate_before</v-icon>
        </v-btn>
        <v-spacer/>
        <filters-panel
          :filter-list="filters"
          :sorting="sorting"
          @change="filterData"
          class="d-inline-block"
        />
      </v-card-actions>

    </div>

    <div :style="widthTable" class="doc-list-panel" v-show="!displayXS || isDataListView">
      <div id="issue-list" v-show="panelExpanded">
        <v-card-actions class="py-0">
          <div class="body-1 ml-2 grey--text text--darken-2">
            {{ $t('tasks') }}
            <v-chip label outlined small>{{ totalElements }}</v-chip>
          </div>
          <v-spacer/>
          <v-btn :disabled="isDataListView" @click="panelExpanded = !panelExpanded" icon>
            <v-icon v-show="!isDataListView">
              {{ panelExpanded ? 'arrow_left' : 'arrow_right' }}
            </v-icon>
          </v-btn>
        </v-card-actions>
        <issue-table infinite-scroll-disabled="busy"
                     infinite-scroll-distance="10"
                     v-infinite-scroll="loadData"
                     :full-width="isDataListView"
                     :items="items"
                     @open="open"/>
      </div>

      <div @click="panelExpanded = true" class="minimized-panel" v-show="!panelExpanded">
        <v-btn class="mb-2" icon>
          <v-icon>{{ panelExpanded ? 'arrow_left' : 'arrow_right' }}</v-icon>
        </v-btn>
        <div class="minimized-panel-label body-1 grey--text text--darken-2">
          {{ $t('tasks') }}
        </div>
      </div>
    </div>

    <router-view :style="panelExpanded ? 'padding-left: 280px;' : 'padding-left: 36px;'" class="doc-view-panel"/>

    <new-issue-dialog @on-save-issue="filterData" ref="newIssueDialog"/>
  </div>
</template>

<script>
import NewIssueDialog from '@/components/issue/NewIssueDialog'
import IssueTable from '../../components/issue/IssueTable'
import { displayServiceMixin } from '../../mixins/dispay-service-mixin'
import { EventBus } from '@/event-bus'
import FiltersPanel from '../filters/FiltersPanel'
import { issueFilterMixin } from '../../mixins/issue-filter-mixin'
import messages from '../../componet-locale/issue-list/messages'

export default {
  name: 'IssueList',
  components: { IssueTable, NewIssueDialog, FiltersPanel },
  mixins: [displayServiceMixin, issueFilterMixin],
  data: () => ({
    panelExpanded: true,
    busy: false,
    cancel: undefined,
    items: [],
    selected: {
      name: ''
    },
    page: 1,
    totalElements: 0
  }),
  i18n: { messages: messages },
  methods: {
    create () {
      if (this.isDataListView) {
        this.$refs.newIssueDialog.open()
        return
      }
      this.$router.push(`/project/${this.selectedProject.id}/ISSUE`).then(() => {
        this.$refs.newIssueDialog.open()
      })
    },
    filterData () {
      this.page = 1
      this.items = []
      this.loadData()
    },
    loadData () {
      const CancelToken = this.$axios.CancelToken
      this.busy = true
      let self = this
      if (this.cancel) {
        this.cancel()
      }
      let requestParam = {
        projectId: this.selectedProject.id
      }
      this.$axios
        .post('/issue/find-pageable-search-result', this.searchRequest,
          {
            params: requestParam,
            cancelToken: new CancelToken(function executor (c) {
              self.cancel = c
            })
          })
        .then(response => {
          if (response.data.content.length) {
            this.items.push(...response.data.content)
            this.page++
            this.busy = false
            this.totalElements = response.data.totalElements
          }
        })
        .catch(thrown => {
          if (this.$axios.isCancel(thrown)) {
            console.error('Request canceled', thrown.message)
          } else {
            thrown ? console.error(thrown.toString()) : console.error('Unknown exception')
          }
        })
    },
    open (item) {
      this.selected = item
      this.panelExpanded = this.windowWidth >= 1060
      EventBus.$emit('openData')
      this.$router.push({
        name: 'view-issue',
        params: {
          projectId: this.selectedProject.id,
          id: item.id
        }
      })
    }
  },
  created () {
    if (this.windowWidth > 1100) {
      this.panelExpanded = true
      return
    }
    this.panelExpanded = this.$route.name === 'issue-list'
  },
  watch: {
    isDataListView (val) {
      if (val) {
        this.panelExpanded = true
        this.filterData()
      }
    }
  },
  computed: {
    isDataListView () {
      return this.$route.name === 'issue-list'
    },
    widthTable () {
      if (this.isDataListView) {
        return 'width: calc(100% - 48px)'
      }
      if (this.panelExpanded) {
        return 'width: 280px;'
      } else {
        return 'width: 36px;'
      }
    },
    searchRequest () {
      let criteria = this.filters
        .map(filter => {
          if (filter.model.value instanceof Array) {
            let values = filter.model.value.map(v => v.id)
            return {
              name: filter.name,
              value: values
            }
          } else {
            return {
              name: filter.name,
              value: filter.model.value
            }
          }
        })

      return {
        page: this.page,
        rowsPerPage: 10,
        sortBy: this.sorting.model.value.sortBy,
        desc: this.sorting.model.value.desc,
        criteria: criteria
          .filter(c => {
            if (c.value instanceof Array) {
              return c.value.length
            } else {
              return c.value
            }
          })
      }
    }
  }
}
</script>

<style>
  #issue-list {
    border-right: solid 1px #d6d2d2;
  }
</style>
