<template>
  <v-select
    v-if=""
    v-model="item[header.alias]"
    :item-text="key"
    :item-value="header.helperPrimaryKey"
    :items="filteredSelectItems"
    :label="header.fieldName"
    :readonly="readonly"
    class="mb-3 helper-values-select"
    dense
    hide-details
    outlined
  >
    <template v-slot:prepend-item>
      <v-text-field
        v-model="inputValue"
        :readonly="readonly"
        class="mx-3 pt-3 mb-2"
        clearable
        dense
        hide-details
        solo
      />
    </template>
  </v-select>

</template>

<script>
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/et-item/messages'

export default {
  name: 'HelperValuesEtItem',
  i18n: { messages },
  props: {
    item: Object,
    header: Object,
    editable: Boolean,
    tableId: Number
  },
  data: () => ({
    selectItems: [],
    inputValue: '',
    initialValue: ''
  }),
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.updateValues()
      this.getInitialValue()
    },
    updateValues () {
      this.$axios
        .post('et/query/search-helper-values', {
          tableId: this.tableId,
          header: this.header,
          value: this.inputValue
        })
        .then(({ data }) => {
          this.selectItems = data
        })
        .catch(e => {
          EventBus.$emit('showErrorMessage', this.$t(e.data.description || e.data.message || 'searchHelperValueError'))
        })
    },
    getInitialValue () {
      this.$axios
        .post('et/query/search-helper-values', {
          tableId: this.tableId,
          header: this.header,
          value: this.item[this.header.alias]
        }, {
          params: {
            equalMode: true
          }
        })
        .then(({ data }) => {
          this.initialValue = data[0]
          this.item[this.header.alias] = data[0][this.header.helperPrimaryKey]
        })
        .catch(er => console.error(er.message))
    }
  },
  computed: {
    filteredSelectItems () {
      return [this.initialValue, ...this.selectItems]
    },
    readonly () {
      return this.header.isPrimaryTableKey
        || this.header.isComputedField
        || !this.editable
    },
    key () {
      return this.header.key.replace(/\s*.*\./gi, "")
    }
  },
  watch: {
    inputValue () {
      this.updateValues()
    }
  }
}
</script>


<style>
.helper-values-select input::placeholder {
  color: black !important;
}
</style>
