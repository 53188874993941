<template>
  <v-dialog fullscreen v-model="dialog" v-if="displayXS">
    <v-card style="overflow-x: hidden">
      <div class="d-flex justify-space-between">
        <v-card-title class="pa-2">
          {{$t('layers')}}
        </v-card-title>
        <div style="cursor: pointer" @click="close" class="mr-2 mt-2">
          <v-icon style="height:fit-content">close</v-icon>
        </div>
      </div>
      <slot name="searchLayers"/>
      <vuedraggable handle=".handle" v-model="layerList">
        <layer-setting-item
          v-for="(layer, i) in filteredLayers"
          :key="i"
          :layer="layer"
          @refresh-map="refreshMap"
          @remove="remove"
          @openTable="openTable"
        />
      </vuedraggable>
    </v-card>
  </v-dialog>
</template>

<script>
import Vuedraggable from 'vuedraggable'
import LayerSettingItem from '@/components/map/helpers/LayerSettingItem.vue'
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/LayersDialogMobile/messages'
import {displayServiceMixin} from "@/mixins/dispay-service-mixin";

export default {
  name: 'LayersDialogMobile',
  i18n: { messages },
  props: {
    layers: Array,
    data: {
      type: Object
    },
    sandbox: {
      type: Boolean,
      default: false
    },
    searchField: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    dialog: false
  }),
  mixins:[displayServiceMixin],
  components: { LayerSettingItem, Vuedraggable },
  methods: {
    open(){
      this.dialog=true
    },
    close(){
      this.dialog=false
    },
    refreshMap () {
      EventBus.$emit('frameResize')
    },
    remove (layer) {
      this.layers.splice(this.layers.indexOf(layer), 1)
      EventBus.$emit('frameResize')
    },
    openTable (layer) {
      if (!this.data) return

      if (layer.type === 'LAYER_POI') {
        this.$router.push({
          name: 'poi-table',
          params: {
            layerId: layer.id
          }
        })
      } else {
        this.$router.push({
          path: '/split-screen',
          query: {
            dataId: layer.id,
            secondDataId: this.data.id
          }
        })
      }
    }
  },
  watch: {
    layerList () {
      this.refreshMap()
    }
  },
  computed: {
    filteredLayers () {
      return this.layers
        .filter(item => item.name.toLowerCase().indexOf(this.searchField.toLowerCase()) > -1)
    },
    layerList: {
      get () {
        return this.layers
      },
      set (val) {
        this.$emit('update:layers', val)
      }
    }
  }
}
</script>
