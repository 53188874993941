<template>
  <div>
    <vuedraggable
      v-model="item.headers"
      handle=".handle"
    >
      <v-card
        v-for="(header, i) in item.headers"
        :key="header.id"
        class="et-header-card my-4 pa-3"
      >
        <v-card-actions class="pa-0 mb-2">
          <v-icon class="handle pointer">mdi-drag-horizontal</v-icon>
          <v-spacer/>
          <v-icon class="pointer" color="red" @click="remove(i)">close</v-icon>
        </v-card-actions>
        <v-text-field
          v-model="header.key"
          :label="$t('key')"
          class="mb-2"
          dense
          hide-details
          outlined
        />
        <v-text-field
          v-model="header.alias"
          :label="$t('alias')"
          class="mb-2"
          dense
          hide-details
          outlined
        />
        <v-text-field
          v-model="header.fieldName"
          :label="$t('title')"
          class="mb-2"
          dense
          hide-details
          outlined
        />
        <v-select
          v-model="header.fieldType"
          :items="fieldTypes"
          :label="$t('fieldType')"
          class="mb-2"
          dense
          hide-details
          outlined
        >
          <template v-slot:selection="{item}">{{ $t(item) }}</template>
          <template v-slot:item="{item}">{{ $t(item) }}</template>
        </v-select>

        <v-textarea
          v-if="header.fieldType === 'LIST'"
          v-model="header.searchOptions"
          :label="$t('searchOptions')"
          :placeholder="$t('useCommaAsDivider')"
          auto-grow
          class="mb-2"
          dense
          hide-details
          outlined
          row-height="12"
        />

        <v-text-field
          v-if="header.updateFromParentTable"
          v-model="header.helperTableName"
          :label="$t('childTableName')"
          class="mb-2"
          dense
          hide-details
          outlined
        />

        <v-text-field
          v-if="header.updateFromParentTable"
          v-model="header.helperPrimaryKey"
          :label="$t('childTablePrimaryKey')"
          class="mb-2"
          dense
          hide-details
          outlined
        />

        <v-text-field
          v-if="header.updateFromParentTable"
          v-model="header.helperUpdateFieldKey"
          :label="$t('updatedFieldKey')"
          class="mb-2"
          dense
          hide-details
          outlined
        />

        <div class="d-flex flex-wrap" style="column-gap:20px">
          <v-checkbox
            v-model="header.isComputedField"
            class="mt-1"
            dense
            hide-details
          >
            <template v-slot:label>
              <div class="color-black">
                {{ $t('computedField') }}
              </div>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="header.excludeFromTable"
            class="mt-1"
            dense
            hide-details
          >
            <template v-slot:label>
              <div class="color-black">
                {{ $t('excludeFromTable') }}
              </div>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="header.isPrimaryTableKey"
            class="mt-1"
            dense
            hide-details
          >
            <template v-slot:label>
              <div class="color-black">
                {{ $t('primaryKey') }}
              </div>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="header.useInInsert"
            v-if="header.isPrimaryTableKey"
            class="mt-1"
            dense
            hide-details
          >
            <template v-slot:label>
              <div class="color-black">
                {{ $t('useInInsert') }}
              </div>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="header.updateFromParentTable"
            class="mt-1"
            dense
            hide-details
          >
            <template v-slot:label>
              <div class="color-black">
                {{ $t('updateThroughParentTable') }}
              </div>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="header.relatedWithMap"
            class="mt-1"
            dense
            hide-details
          >
            <template v-slot:label>
              <div class="color-black">
                {{ $t('relatedWithMap') }}
              </div>
            </template>
          </v-checkbox>
        </div>
        <v-text-field
          v-if="header.relatedWithMap"
          v-model="header.lpKey"
          :label="$t('lpKey')"
          class="my-2"
          dense
          hide-details
          outlined
        />
        <v-text-field
          v-if="header.relatedWithMap"
          v-model="header.lpAlias"
          :label="$t('lpAlias')"
          class="mb-2"
          dense
          hide-details
          outlined
        />
      </v-card>
    </vuedraggable>
    <v-btn
      class="full-width"
      style="border: 1px dashed lightgray"
      text
      @click="add"
    >
      <v-icon>mdi-plus</v-icon>
      {{ $t('button.add') }}
    </v-btn>
  </div>
</template>
<script>

import Vuedraggable from 'vuedraggable'
import messages from '@/componet-locale/et-edit/messages'
import { EventBus } from '@/event-bus'

export default {
  name: 'EtHeaderEditor',
  components: { Vuedraggable },
  i18n: { messages },
  props: {
    item: Object
  },
  methods: {
    add () {
      this.item.headers.push({
        fieldType: 'STRING'
      })
    },
    remove (i) {
      this.item.headers.splice(i, 1)
    },
    checkHeadersValid () {
      if (this.item.headers && this.item.headers.length === 0) {
        EventBus.$emit('showErrorMessage', this.$t('addAnyHeaders'))
        return false
      }
      if (this.item.headers.filter(el => el.isPrimaryTableKey).length > 1) {
        EventBus.$emit('showErrorMessage', this.$t('primaryKeyMayBeOnlyOne'))
        return false
      }
      const aliases = this.item.headers.map(el => el.alias)
      if (aliases.filter((el, i) => aliases.indexOf(el) !== i).length > 0) {
        EventBus.$emit('showErrorMessage', this.$t('aliasesMustBeDifferent'))
        return false
      }
      const keys = this.item.headers.map(el => el.key)
      if (keys.filter((el, i) => keys.indexOf(el) !== i).length > 0) {
        EventBus.$emit('showErrorMessage', this.$t('keysMustBeDifferent'))
        return false
      }
      return true
    }
  },
  computed: {
    fieldTypes () {
      return ['STRING', 'NUMBER', 'DATE', 'BOOLEAN', 'LIST']
    }
  }
}
</script>

<style scoped>

.color-black {
  color: black;
}

.et-header-card {
  border: 1px solid lightgray;
  box-shadow: none;
  background: #f6f4f2
}

</style>
