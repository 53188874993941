<template>
  <div style="width: 100%" :style="{paddingBottom: displayXS ? '56px' : ''}">
    <grid-layout :col-num="12"
                 :is-draggable="editableMode"
                 :is-mirrored="false"
                 :is-resizable="editableMode"
                 :layout="widgets"
                 :margin="[16, 10]"
                 :responsive="displayXS"
                 :row-height="30"
                 :use-css-transforms="true"
                 :vertical-compact="true"
                 @update:layout="value => $emit('update:widgets',value)"
    >

      <grid-item v-for="widget in widgets"
                 :key="widget.i"
                 :h="widget.h"
                 :i="widget.i"
                 :minH="WIDGETS[widget.type].minH || 1"
                 :minW="WIDGETS[widget.type].minW || 1"
                 :w="widget.w"
                 :x="widget.x"
                 :y="widget.y"
                 dragAllowFrom="#dragWidgetButton"
                 dragIgnoreFrom=""
                 style="overflow: hidden"
      >
        <div class="d-flex flex-column widget-controls">
          <v-btn v-show="editableMode"
                 class="mr-3 mt-3" color="red"
                 fab outlined
                 right style="background-color: white"
                 x-small @click="remove(widget.id)">
            <v-icon>close</v-icon>
          </v-btn>
          <v-btn v-show="editableMode"
                 id="dragWidgetButton" class="mr-3 mt-3"
                 fab outlined
                 style="background-color: white" x-small>
            <v-icon>mdi-drag-variant</v-icon>
          </v-btn>
        </div>

        <component
          :is="getComponentFromWidget(widget)"
          :editable="editableMode"
          :widget="widget"
        />

      </grid-item>
    </grid-layout>
  </div>
</template>

<script>
import VueGridLayout from 'vue-grid-layout'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import MapWidget from '../widget/MapWidget'
import ChartWidget from '../widget/ChartWidget'
import { EventBus } from '@/event-bus'
import TableWidget from '@/components/widget/TableWidget'
import messages from '../../componet-locale/widget-bar/messages'
import TextWidget from '@/components/widget/TextWidget.vue'
import { WIDGETS } from '@/services/widget-service'

export default {
  name: 'WidgetBar',
  computed: {
    WIDGETS () {
      return WIDGETS
    }
  },
  mixins: [displayServiceMixin],
  props: {
    widgets: {
      type: Array,
      required: true
    },
    editableMode: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TextWidget,
    TableWidget,
    ChartWidget,
    MapWidget,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
  },
  i18n: { messages: messages },
  methods: {
    remove (id) {
      this.$axios
        .get('/widget/remove', { params: { id: id } })
        .then(() => {
          this.$emit('onRemove')
          EventBus.$emit('showInfoMessage', this.$t('widgetRemoved'))
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
    },
    getComponentFromWidget (widget) {
      switch (widget.type) {
        case 'MAP':
          return MapWidget
        case 'TABLE':
          return TableWidget
        case 'CHART':
          return ChartWidget
        case 'TEXT':
          return TextWidget
      }
    }
  }
}
</script>

<style scoped>
.widget-controls {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2
}
</style>
