<template>
  <v-row v-if="$vuetify.breakpoint.xs" no-gutters>
    <v-col cols="6" v-for="(file) in files">
      <v-card
        @click="view(file)"
        :ripple="false"
        outlined
        class="mx-4 my-3">
        <v-container>
          <v-icon class="text-left">{{ file.isShared ? 'group': '' }}</v-icon>
          <v-img
            class="mx-auto"
            width="80"
            height="80"
            :src="`/image/${file.type}.png`">
          </v-img>
          <v-card-actions>
            <v-spacer/>
            <v-btn icon @click.native.stop="showAction(file)">
              <v-icon>more_horiz</v-icon>
            </v-btn>
          </v-card-actions>
        </v-container>
        <div class="black--text file-name ma-2">
          {{ file.name }}
        </div>
      </v-card>
    </v-col>
  </v-row>

  <drag-select
    v-else
    class="mt-4"
    ref="dragSelect"
    attribute="id"
    @change="changeSelection">
        <v-container
          @dblclick="view(file)"
          style="display: inline-block"
          :class="{'item-selected': selectedIds.includes(`${file.id}`)}"
          v-for="(file) in files" :id="String(file.id)"
          @contextmenu.prevent="contextMenu($event)"
          class="mx-4 my-3 file-card">
            <v-icon style="height: 20px" class="text-left">{{ file.isShared ? 'group': '' }}</v-icon>
            <v-img
              class="mx-auto"
              width="80"
              height="80"
              :src="`/image/${file.type}.png`">
            </v-img>
          <div class="file-name mt-4">{{ file.name }}</div>
        </v-container>
  </drag-select>

</template>

<script>

import DragSelect from 'drag-select-vue'

export default {
  name: 'FilesGridView',
  components: { DragSelect },
  props: {
    selected: {
      type: Array,
      default: []
    },
    items: {
      type: Array,
      default: []
    },
    shiftPressed: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'selected',
    event: 'update'
  },
  data: () => ({
    selectedIds: []
  }),
  computed: {
    files () {
      return this.items
    }
  },
  methods: {
    contextMenu (event) {
      this.$emit('contextmenu', event)
    },
    showAction (item) {
      this.$emit('show-action', item)
    },
    changeSelection (item) {
      if (this.selectedIds.includes(item[0])) return
      if (this.shiftPressed) this.selectedIds.push(item[0])
      else this.selectedIds = item
      const selectedFiles = this.files.filter(item => this.selectedIds.includes(`${item.id}`))
      this.updateModel(selectedFiles)
    },
    view (item) {
      this.$emit('view', [item])
      this.$emit('update', [])
    },
    updateModel (value) {
      this.$emit('update', value)
    }
  },
  watch: {
    selected () {
      if (this.selected.length === 0) {
        this.selectedIds = []
        if (this.$refs.dragSelect && this.$refs.dragSelect.intersected.length > 0) { this.$refs.dragSelect.intersected = [] }
      }
    }
  }
}
</script>

<style scoped>

  .file-card{
    width: 195px;
    border: thin solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }

  .item-selected {
    background: #ACCEF7 !important;
  }

  .file-name {
    text-align: center;
    overflow: hidden;
    font-size: 12px;
    word-wrap: break-word;
    line-height: 1.5em;
    height: 4.5em;
  }

</style>
