<template>
  <div>
    <v-list>
      <v-list-item
        :key="item.id"
        @click="()=>{}"
        v-for="item in listOfData"
      >
        <v-list-item-action
          @click="viewFile(item)"
          class="mr-4"
        >
          <v-img
            class="mx-auto"
            width="35"
            height="40"
            :src="`/image/${item.type}.png`">
          </v-img>
        </v-list-item-action>
        <v-list-item-content
          @click="viewFile(item)"
        >
          {{ item.name }}
          <v-list-item-subtitle>
            {{ getUserFullName(item.owner) }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <span class="caption">{{ formatDate(item.creationDate) }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            @click="openConfirmationDialog(item)"
            icon
          >
            <v-icon>delete</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <confirmation-dialog
      title="Вы действительно хотите удалить файл"
      ref="confirmationDialog"
      @confirm="remove"
    />
    <file-viewer
      :storage-url="fileEntity => isAuthenticated
        ? `files/get-attached-file/${fileEntity.id}`
        : `public-data/get-lp-attached-file/${fileEntity.id}`"
      ref="fileViewer"
    />
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog.vue'
import FileViewer from '@/components/drive/FileViewer.vue'

export default {
  name: 'LpFileAttachments',
  components: { FileViewer, ConfirmationDialog },
  props: {
    listOfData: Array
  },
  methods: {
    viewFile (item) {
      this.$refs.fileViewer.open(item)
    },
    openConfirmationDialog (item, evt) {
      this.$refs.confirmationDialog.open(item)
      evt.preventDefault()
    },
    remove (item) {
      this.$axios
        .delete('files/attached-file', {
          params: {
            id: item.id
          }
        })
        .then(() => {
          this.$emit('reload')
          EventBus.$emit('showInfoMessage', 'Файл удален')
        })
        .catch(() => EventBus.$emit('showErrorMessage', 'При попытке удалить файл произошла ошибка'))
    }
  }
}
</script>
