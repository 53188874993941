import { render, staticRenderFns } from "./TextWidget.vue?vue&type=template&id=1fb6f70d&scoped=true&"
import script from "./TextWidget.vue?vue&type=script&lang=js&"
export * from "./TextWidget.vue?vue&type=script&lang=js&"
import style0 from "./TextWidget.vue?vue&type=style&index=0&id=1fb6f70d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fb6f70d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VFadeTransition,VHover,VIcon})
