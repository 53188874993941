<template>
  <v-dialog v-if="dialog && headers" v-model="dialog" persistent width="900">
    <v-card class="overflow-x-hidden" height="90vh">
      <v-card-text class="pa-0 overflow-auto" style="height: calc(90vh - 52px)">
        <v-card-text>
          <component
            :is="getComponentFromAlias(header)"
            v-for="header in sortedHeaders"
            :key="header.key"
            :editable="editable"
            :header="header"
            :item="item"
            :tableId="tableId"
          />
        </v-card-text>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined text @click="close">{{ $t('button.close') }}</v-btn>
        <v-spacer/>
        <v-btn color="black" outlined @click="openPrintDialog">{{ $t('button.print') }}</v-btn>
        <v-btn color="primary" outlined @click="makeUpdateQuery">{{ $t('button.save') }}</v-btn>
      </v-card-actions>
    </v-card>
    <print-template-selector-dialog ref="printTemplateSelectorDialog" @print="print"/>
  </v-dialog>
</template>

<script>
import EtHeaderEditor from '@/components/et/EtHeaderEditor.vue'
import messages from '@/componet-locale/et-item/messages'
import PrintTemplateSelectorDialog from '@/components/admin/print-template/PrintTemplateSelectorDialog.vue'
import { EventBus } from '@/event-bus'
import StringEtItem from '@/components/et/utils/StringEtItem.vue'
import ListEtItem from '@/components/et/utils/ListEtItem.vue'
import BooleanEtItem from '@/components/et/utils/BooleanEtItem.vue'
import HelperValuesEtItem from '@/components/et/utils/HelperValuesEtItem.vue'

export default {
  name: 'EtItemDialog',
  components: { PrintTemplateSelectorDialog, EtHeaderEditor },
  props: {
    primaryTableName: String
  },
  i18n: { messages },
  data: () => ({
    dialog: false,
    item: null,
    headers: null,
    tableHeaders: [{ value: 'key' }, { value: 'value' }],
    tableId: null
  }),
  methods: {
    open (headers, item, tableId) {
      this.dialog = true
      this.headers = JSON.parse(JSON.stringify(headers))
      this.item = JSON.parse(JSON.stringify(item))
      this.tableId = tableId
    },
    close () {
      this.dialog = false
    },
    openPrintDialog () {
      this.$refs.printTemplateSelectorDialog.open()
    },
    makeUpdateQuery () {
      if (!this.primaryHeader) {
        EventBus.$emit('showErrorMessage', this.$t('updatingTableNotPossible'))
        return
      }
      const fieldsValue = []
      for (const prop in this.item) {
        if (this.headers.find(el => el.alias === prop)
          && !this.headers.find(el => el.alias === prop).isComputedField
        ) {
          fieldsValue.push({
            header: this.headers.find(it => it.alias === prop),
            value: this.item[prop]
          })
        }
      }
      if (fieldsValue.length === 0) {
        EventBus.$emit('showErrorMessage', this.$t('updatingTableNotPossible'))
        return
      }
      this.$axios.post('et/query/update', {
        tableId: this.tableId,
        fieldsValue
      })
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('tableUpdatedSuccessfully'))
          this.$emit('tableUpdated')
          this.close()
        })
        .catch(e => {
          if (e.status === 403) {
            EventBus.$emit('showErrorMessage', this.$t('noPermission'))
          } else {
            EventBus.$emit('showErrorMessage', this.$t(e.data.description || e.data.message || 'UPDATE_ERROR'))
          }
        })
    },
    print () {
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')

      let rows = []
      this.sortedHeaders.forEach(header => {
        rows.push(`<div><b>${header.fieldName}: </b>${this.item[header.alias]}</div>`)
      })
      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
            <style>
                div {padding: 10px}
            </style>
        </head>
        <body>
          ${rows.join('')}
        </body>
      </html>`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    },
    getComponentFromAlias (header) {
      if (header.updateFromParentTable) {
        return HelperValuesEtItem
      }
      switch (header.fieldType) {
        case 'LIST':
          return ListEtItem
        case 'BOOLEAN':
          return BooleanEtItem
        default:
          return StringEtItem
      }
    }
  },
  computed: {
    sortedHeaders () {
      return this.headers.sort((prev, next) => prev.showIndex - next.showIndex)
    },
    primaryHeader () {
      return this.headers.find(el => el.isPrimaryTableKey)
    },
    editable () {
      return !!this.primaryHeader
        || !!this.primaryTableName
    }
  }
}
</script>
