import { CONDITIONS } from "@/components/et/header-rules/utils";

export function getSortingConfigFromHeaders (headers) {
  if (headers) {
    return headers.map((el) => ({
      index: el.index || 0,
      key: el.key,
      fieldName: el.fieldName,
      direction: el.direction || 'NULL'
    }))
  }
  return []
}

export function getFilterFromHeaders (headers) {
  if (headers) {
    return headers.map(h => ({
      key: h.key,
      alias: h.alias,
      fieldName: h.fieldName,
      value: [],
      isComputedField: h.isComputedField,
      predicate: getDefaultPredicate(h)
    }))
  }
}

function getDefaultPredicate (header) {
  switch (header.fieldType) {
    case 'STRING':
      return CONDITIONS.LIKE
    case 'LIST':
      return CONDITIONS.LIKE
    default:
      return CONDITIONS.EQUAL
  }
}

export function getDirection (direction) {
  switch (direction) {
    case 'ASC':
      return 'DESC'
    case 'DESC':
      return 'NULL'
    default:
      return 'ASC'
  }
}

export function getDirectionIcon (direction) {
  switch (direction) {
    case 'ASC':
      return 'mdi-sort-ascending'
    case 'DESC':
      return 'mdi-sort-descending'
    default:
      return ''
  }
}

export const etConst = {
  PAGINATION_ITEMS_PER_PAGE: [10, 20, 50, 100],
  DEFAULT_PAGINATION: {
    page: 1,
    itemsPerPage: 20,
    itemsCount: 20
  }
}
