<template>
  <v-container>
    <v-layout align-center justify-center wrap :style="{marginBottom: displayXS?'56px':'0px'}">
      <v-flex lg10 md12 sm12 xl8 xs12>
        <v-card class="elevation-0">
          <v-card-title primary-title class="px-0">
            <v-text-field
              v-model="searchField"
              prepend-inner-icon="search"
              solo
              clearable
              hide-details
              :placeholder="$t('searchFieldPlaceholder')"
            />
            <v-spacer/>
            <flexible-button
              @click="$refs.projectViewDialog.open()"
              icon="add"
              :label="$t('createProject')"
              v-if="setAbility('ADMIN')"/>
          </v-card-title>
        </v-card>

        <v-hover :key="item.id" v-for="item in list" v-slot:default="{ hover }">
          <v-card
            color="grey lighten-4"
            :elevation="hover ? 4 : 0"
            @click.native="select(item.userProject.project, 'home', $event)"
            outlined
            class="mt-3 pointer"
          >
            <v-card-title>
              <span v-html="highlight(item.userProject.project.name)"/>
              <v-spacer/>
              <v-btn @click="favoriteProject(item.userProject, $event)" icon v-show="!displayXS">
                <v-icon color="yellow darken-3" v-if="item.userProject.favorite">star</v-icon>
                <v-icon color="grey" v-else>star_border</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text class="pt-0" v-if="item.userProject.project.description">
              {{ item.userProject.project.description }}
            </v-card-text>

            <v-card-text class="pt-0 px-2">
              <v-tooltip top color="black">
                <template v-slot:activator="{ on }">
                  <v-chip
                    @click="select(item.userProject.project, 'dashboard', $event)"
                    class="ma-1"
                    label
                    outlined
                    v-on="on"
                    v-if="item.numberOfWidgets"
                  >
                    <v-icon left>dashboard</v-icon>
                    {{ item.numberOfWidgets }}
                  </v-chip>
                </template>
                <span>{{ $t('dashboard') }}</span>
              </v-tooltip>
              <v-tooltip top v-for="type in dataTypeList" color="black">
                <template v-slot:activator="{ on }">
                  <v-chip
                    @click="select(item.userProject.project, type.link, $event)"
                    class="ma-1"
                    label
                    outlined
                    v-if="item.numberOfDataByType[type.value]"
                    v-on="on"
                  >
                    <v-icon left>{{ type.icon }}</v-icon>
                    {{ item.numberOfDataByType[type.value] }}
                  </v-chip>
                </template>
                <span>{{ $t(type.title) }}</span>
              </v-tooltip>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-flex>
    </v-layout>
    <project-view-dialog @on-save="getProjects()" ref="projectViewDialog"/>
  </v-container>
</template>

<script>
import FlexibleButton from './utils/FlexibleButton'
import { mapActions } from 'vuex'
import ProjectViewDialog from './project/ProjectViewDialog'
import { EventBus } from '@/event-bus'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import messages from '../componet-locale/home/messages'
import projectMessages from '../shared-locale/project/messages'

export default {
  name: 'Home',
  components: { ProjectViewDialog, FlexibleButton },
  mixins: [displayServiceMixin],
  i18n: {
    messages: messages,
    sharedMessages: projectMessages
  },
  methods: {
    ...mapActions([
      'selectProject'
    ]),
    select (project, path, event) {
      this.selectProject({
        project: project,
        path: path
      })
      event.stopPropagation()
    },
    favoriteProject (userProject, event) {
      let url = userProject.favorite ? 'project/remove-from-favorites' : 'project/add-to-favorites'
      userProject.favorite = !userProject.favorite
      this.$axios
        .post(url, null, { params: { id: userProject.id } })
        .then(() => EventBus.$emit('changeFavoriteProject'))
        .catch(() => {
          EventBus('showErrorMessage', 'error')
          userProject.favorite = !userProject.favorite
        })

      event.stopPropagation()
    },
    getProjects () {
      this.$axios
        .get('project/get-project-list')
        .then(response => {
          this.projectDetailsList = response.data
        })
    },
    highlight (name) {
      let re = new RegExp(this.searchField, 'ig')
      return this.searchField ? name.replace(re, `<em>$&</em>`) : name
    },
    background (name) {
      let hash = 0
      for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash)
        hash = hash & hash
      }
      hash = Math.abs(hash)
      const leftColor = hash % 360
      const rightColor = hash + 50
      return {
        'background-image': `linear-gradient(to bottom right, hsla(${leftColor}, 70%, 50%, 0.5), hsl(${rightColor}, 70%, 50%))`
      }
    }
  },
  mounted () {
    this.getProjects()
  },
  data: () => ({
    searchField: '',
    projectDetailsList: []
  }),
  computed: {
    list () {
      if (!this.searchField) return this.projectDetailsList
      return this.projectDetailsList
        .filter(item =>
          item.userProject.project.name.toLowerCase().indexOf(this.searchField.toLowerCase()) > -1
        )
    }
  }
}
</script>
