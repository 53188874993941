<template>
  <v-combobox :item-text="(item) => $t(item[model.itemText])"
            :items="items"
            :placeholder="label"
            @change="$emit('change')"
            chips
            class="ml-2 input-filter"
            clearable
            dense
            hide-details
            multiple
            return-object
            small-chips
            v-model="model.value">
    <template v-slot:selection="{ item, index }">
      <v-chip :color="item.color" dark label small v-if="index === 0">
        {{ $t(item[model.itemText]) }}
      </v-chip>
      <span class="grey--text caption" v-if="index === 1">+{{ model.value.length - 1 }}</span>
    </template>
<!--    <template v-slot:item="{ item, attrs, on }">-->
<!--      <v-list-item v-bind="attrs" v-on="on" dense>-->
<!--        <template v-slot:default="{ active, toggle }">-->
<!--          <v-checkbox-->
<!--            :input-value="active"-->
<!--            :true-value="item"-->
<!--            @click="toggle"/>-->
<!--          {{ item.title }}-->
<!--          <v-spacer/>-->
<!--          <v-avatar :color="item.color" size="16"/>-->
<!--        </template>-->
<!--      </v-list-item>-->
<!--    </template>-->
  </v-combobox>
</template>

<script>
import messages from '../../shared-locale/issue-status/messages'

export default {
  name: 'StatusFilter',
  props: {
    model: {
      type: Object,
      default: {
        value: [],
        listDataSource: '',
        itemText: ''
      }
    },
    label: {
      type: String,
      default: ''
    }
  },
  i18n: { messages: messages },
  computed: {
    items () {
      return this.model.listDataSource
    }
  }
}
</script>
