export const WIDGETS = {
  MAP: {
    title: 'MAP',
    minH: 5,
    minW: 4
  },
  CHART: {
    title: 'CHART',
    minH: 6,
    minW: 6
  },
  TABLE: {
    title: 'TABLE',
    minH: 5,
    minW: 4
  },
  TEXT: {
    title: 'TEXT',
    minH: 3,
    minW: 3
  }
}

export const WIDGET_TYPES = Object.keys(WIDGETS)
