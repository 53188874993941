var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"et-viewer"},[_c('div',{staticClass:"d-flex align-center px-2",staticStyle:{"height":"50px"}},[_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.openEtAddRowDialog}},[_vm._v(_vm._s(_vm.$t('addRow')))])],1),(_vm.filteredHeaders)?_c('v-data-table',{attrs:{"id":"et-table","headers":_vm.filteredHeaders,"items":_vm.table.body,"loading-text":_vm.$t('loading'),"no-data-text":_vm.$t('noData'),"no-results-text":_vm.$t('noResults'),"disable-filtering":"","disable-pagination":"","disable-sort":"","hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('tr',_vm._l((_vm.filteredHeaders),function(header){return _c('td',{staticClass:"pb-1",staticStyle:{"vertical-align":"baseline"}},[_c('div',{staticClass:"d-flex flex-column align-start justify-start flex-grow-1"},[_c('div',{staticClass:"et-table-header-text d-flex v-btn pointer",on:{"click":function (e) { return _vm.setDirection(header, e); }}},[_c('div',{staticClass:"bold"},[_vm._v(" "+_vm._s(header.fieldName)+" "),_c('v-icon',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.getDirectionIcon(header))+" ")])],1),(_vm.sortingConfig.find(function (el) { return el.index > 1; }))?_c('div',[_vm._v(" "+_vm._s(_vm.getSortingIndex(header))+" ")]):_vm._e()]),(_vm.filter.length > 0)?_c('div',[_c(_vm.getFilterComponent(header),{tag:"component",attrs:{"filter":_vm.filter,"getIndexOfFilter":_vm.getIndexOfFilter,"header":header,"tableId":_vm.data.id}})],1):_vm._e()])])}),0)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{on:{"click":function($event){return _vm.openEtItemDialog(item)}}},_vm._l((_vm.filteredHeaders),function(header){return _c('td',{style:(_vm.getStyleFromHeader(header ,_vm.data.rules, item[header.alias]))},[(header.fieldType  === 'BOOLEAN')?_c('v-icon',[_vm._v(" "+_vm._s(item[header.alias] ? 'mdi-check' : 'mdi-close')+" ")]):_c('div',{staticClass:"d-flex"},[_c('span',{style:(header.relatedWithMap && item[header.lpAlias] ? {
                  color: header.relatedWithMap && item[header.lpAlias] ? 'blue' : 'black',
                  cursor: header.relatedWithMap && item[header.lpAlias] ? 'pointer' : 'default'
                } : {
                  cursor: 'default'
                }),on:{"click":function (e) { return _vm.openLp(e, item, header); }}},[_vm._v(" "+_vm._s(item[header.alias] || '')+" ")])])],1)}),0)]}}],null,false,3215477873)}):_vm._e(),_c('d-pagination',{staticClass:"d-pagination",attrs:{"items-per-page-list":_vm.etConst.PAGINATION_ITEMS_PER_PAGE,"pagination":_vm.pagination},on:{"openPage":_vm.openPage}}),_c('et-edit-query-dialog',{directives:[{name:"show",rawName:"v-show",value:(_vm.setAbility('SYSTEM_ADMIN')),expression:"setAbility('SYSTEM_ADMIN')"}],ref:"etEditSelectDialog",on:{"save":_vm.onEditQuery}}),_c('et-item-dialog',{ref:"etItemDialog",attrs:{"primaryTableName":_vm.primaryTableName},on:{"tableUpdated":_vm.updateBody}}),_c('et-rules-dialog',{ref:"etRulesDialog",on:{"save":_vm.onEditQuery}}),_c('layer-poi-dialog',{ref:"layerPoiDialog",attrs:{"actions-toolbar":"","map-only-mode":"","map-widget":""}}),_c('et-import-dialog',{ref:"etImportDialog",on:{"updateTable":_vm.updateBody}}),_c('et-create-dialog',{ref:"etCreateDialog",on:{"save":_vm.onSaveCopy}}),_c('et-add-row-dialog',{ref:"etAddRowDialog",attrs:{"table-id":_vm.data.id},on:{"save":_vm.updateBody}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }