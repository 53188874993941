<template>
  <view-data-page
    :data="data"
    :selected-view="selectedView"
    :tab-list="tabList"
    :split-screen-mode="splitScreenMode"
    @selectView="selectView"
  >
    <template slot="doc">
      <chart :chart="chart" :table="table" class="mt-4"/>
      <v-card-title class="pb-2 mx-6 px-3">
        <v-icon
          color="primary"
          v-html="'tune'"
          left
        />
        Настройки
        <v-spacer/>
        <v-btn
          @click="openInNewTab(data.table)"
          color="primary">
          <v-icon left>table_chart</v-icon>
          Перейти к таблице
        </v-btn>
      </v-card-title>
      <div
        class="pa-6"
        v-if="chart"
        style="max-width: 600px"
      >
        <v-card-text class="pt-0">
          <v-switch
            label="Построчно"
            v-model="chart.showDetails"
          />
          <v-combobox
            :items="table.headers"
            item-text="name"
            label="Поля таблицы"
            return-object
            v-if="chart.showDetails"
            v-model="chart.mainColumn"
          />
          <v-text-field
            label="Параметр"
            v-if="!chart.showDetails"
            v-model="chart.name"
          />
          <v-select
            label="Тип графика"
            v-model="chart.type"
            :items="chartTypeList"
          />

          <h3 class="mt-2">Поля из таблицы</h3>
          <v-layout row wrap class="px-3">
            <v-flex
              v-for="header in chart.headers"
              md4 sm6 xs12
            >
              <v-checkbox
                :label="header.name"
                hide-details
                v-model="header.isValue"
              />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions
          v-show="setAbility('MODERATOR')"
        >
          <v-spacer/>
          <v-btn
            color="primary"
            @click="save"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </div>
    </template>
  </view-data-page>
</template>

<script>
import Chart from './Chart'
import ViewDataPage from '../utils/ViewDataPage'
import { EventBus } from '@/event-bus'

export default {
  name: 'ChartView',
  props: {
    splitScreenMode: {
      type: Boolean,
      default: false
    },
    dataId: Number
  },
  components: { ViewDataPage, Chart },
  data: () => ({
    selectedView: 'comments',
    chartTypeList: ['LINE', 'BAR', 'PIE', 'RADAR', 'DOUGHNUT'],
    tabList: [
      {
        icon: 'message',
        view: 'comments'
      }
    ],
    data: {
      name: '',
      owner: {
        firstName: ''
      },
      project: {
        name: ''
      }
    },
    chart: {
      headers: []
    },
    table: {},
    active: 0,
    url: ''
  }),
  methods: {
    save () {
      this.data.value = JSON.stringify(this.chart)
      this.$axios
        .post('/chart/save', this.data)
        .then(() => {
          EventBus.$emit('showSuccessMessage', 'Внесены изменения')
        })
        .catch(error => {
          console.error(error)
        })
    },
    selectView (view) {
      this.selectedView = view
    },
    init () {
      let id = this.dataId || this.$route.params.id
      this.$axios
        .get('data/get', { params: { id: id } })
        .then(response => {
          this.data = response.data
          this.chart = JSON.parse(this.data.value)
          this.loadTable(this.data.table.id)
        })
        .catch(reason => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
          console.error(reason.data.message)
        })
    },
    loadTable (id) {
      this.$axios
        .get('/table/get-table/' + id)
        .then(response => {
          this.table = JSON.parse(response.data.table)
        })
        .catch(reason => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
          console.error(reason.data.message)
        })
    }
  },
  created () {
    this.init()
  },
  watch: {
    '$route.params.id' () {
      this.init()
    }
  }
}
</script>
