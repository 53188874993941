<template>
  <v-select
    v-model="item[header.alias]"
    :items="selectStrings"
    :label="header.fieldName"
    :readonly="readonly"
    class="mb-3"
    dense
    hide-details
    outlined
  >
    <template v-slot:prepend-item>
      <v-text-field
        v-model="inputValue"
        :readonly="readonly"
        class="mx-3 pt-3 mb-2"
        clearable
        dense
        hide-details
        solo
      />
    </template>
  </v-select>

</template>

<script>
export default {
  name: 'ListEtItem',
  props: {
    item: Object,
    header: Object,
    editable: Boolean,
    tableId: Number
  },
  data: () => ({
    selectItems: [],
    inputValue: '',
    initialValue: ''
  }),
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.updateAutocomplete()
      this.initialValue = this.item[this.header.alias]
    },
    updateAutocomplete () {
      this.$axios
        .post('et/query/autocomplete', {
          tableId: this.tableId,
          header: this.header,
          value: this.inputValue
        })
        .then(({ data }) => {
          this.selectItems = data
        })
        .catch(e => console.error(e))
    }
  },
  computed: {
    selectStrings () {
      const res = []
      res.push(...this.selectItems.map(el => el.value))
      res.unshift(this.initialValue)
      return res
    },
    readonly () {
      return this.header.isPrimaryTableKey
        || this.header.isComputedField
        || !this.editable
    }
  },
  watch: {
    inputValue () {
      this.updateAutocomplete()
    }
  }
}
</script>
