<template>
  <v-container fill-height fluid>
    <v-layout align-center class="pa-4" justify-center>
      <v-flex xl4 lg5 md7 sm8 xs12>
        <v-card :outlined="!displayXS" class="elevation-0 py-5" id="login-card">
          <v-card-title class="pb-0" color="primary">
            <img :src="logo" style="height: 48px"/>
            <v-spacer/>
<!--            <v-tooltip right>-->
<!--              <template v-slot:activator="{ on }">-->
<!--                <v-chip class="ml-2" small v-on="on" outlined color="red darken-3">-->
<!--                  {{ $t('betaVersion') }}-->
<!--                </v-chip>-->
<!--              </template>-->
<!--              <span>{{ $t('title') }}</span>-->
<!--            </v-tooltip>-->
          </v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-text-field @keyup.enter="login"
                            name="login"
                            :placeholder="$t('email')"
                            prepend-icon="person"
                            type="text"
                            v-model="username"/>
              <v-text-field @keyup.enter="login"
                            id="password"
                            name="password"
                            :placeholder="$t('password')"
                            prepend-icon="lock"
                            type="password"
                            v-model="password"/>
            </v-form>
            <div class="my-2" style="text-align: right">
              <router-link class="router-link" to="/reset-password">
               {{ $t('forgotPassword') }}
              </router-link>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn text to="/registration">{{ $t('createAccount')}}</v-btn>
            <v-spacer/>
            <v-btn :loading="loading" @click="login" color="primary" width="120">{{ $t('entrance') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { EventBus } from '@/event-bus'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import axios from 'axios'
import messages from '../componet-locale/login-page/messages'

export default {
  data: () => ({
    username: '',
    password: '',
    loading: false,
    logo: ''
  }),
  i18n: { messages: messages },
  mixins: [displayServiceMixin],
  methods: {
    login () {
      this.loading = true
      this.$store
        .dispatch('login', { username: this.username, password: this.password })
        .then(() => {}, error => {
          if (!error || !error.status) {
            EventBus.$emit('showErrorMessage', this.$t('noServerConnection'))
          } else if (error.status === 401 || error.status === 403) {
            EventBus.$emit('showErrorMessage', this.$t('checkCred'))
          } else {
            EventBus.$emit('showErrorMessage', this.$t('error'))
          }
        })
        .catch(reason => {
          EventBus.$emit('showErrorMessage', reason.toString())
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    axios
      .get('settings/get-general')
      .then(response => this.logo = response.data.COMPANY_LOGO)
  }
}
</script>
