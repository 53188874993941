<template>
  <feature-card @close="$emit('close')">
    <template v-slot:name>
      {{ name }}
    </template>
    <template v-slot:info>
      <action-toolbar
        :actions="actions"
        class="px-3"
        @createIssue="createIssue"
        @edit="$emit('edit')"
        @remove="openConfirmationDialog"
        @share="share"
      />
    </template>
    <template v-slot:fields>
      <wfs-fields :feature="feature"/>
    </template>
    <template v-slot:actions>
      <v-btn
        outlined
        @click=""
      >
        Детали
      </v-btn>
      <v-spacer/>
      <v-btn
        color="primary"
        outlined
        @click="save"
      >
        Сохранить
      </v-btn>
    </template>

    <confirmation-dialog
      ref="removeConfirmationDialog"
      title="Вы действительно хотите удлаить объект"
      @confirm="remove"
    />

  </feature-card>
</template>

<script>
import FeatureCard from '@/components/map/FeatureCard'
import WfsFields from '@/components/map/geoserver/WfsFeatureFields'
import ActionToolbar from '@/components/utils/ActionToolbar'
import { EDIT, OPEN_ISSUES, REMOVE, SHARE } from '@/components/map/helpers/map-actions'
import { GML, WFS } from 'ol/format'
import { EventBus } from '@/event-bus'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog'

export default {
  name: 'GeoServerFeatureCard',
  components: { ConfirmationDialog, ActionToolbar, WfsFields, FeatureCard },
  props: { feature: Object },
  methods: {
    edit () {
      this.$emit('editFeature')
    },
    openConfirmationDialog () {
      this.$refs.removeConfirmationDialog.open({ name: this.name })
    },
    share () {
      // TODO impl
    },
    createIssue () {
      // TODO impl
    },
    refresh () {
      EventBus.$emit('refreshMap')
    },
    save () {
      let { layer } = this.feature
      let formatWFS = new WFS()
      let gml = new GML({
        featureNS: layer._ds.targetNamespace,
        featurePrefix: layer._ds.targetPrefix,
        featureType: layer._ds.typeName,
        srsName: 'EPSG:3857'
      })
      let node = formatWFS.writeTransaction(null, [this.feature], null, gml)
      let s = new XMLSerializer()
      let value = s.serializeToString(node)
      this.$axios
        .post(window.location.origin + '/geoserver/wfs', value, {
          headers: {
            'Content-Type': 'text/xml'
          },
          params: {
            service: 'wfs',
            outputFormat: ''
          }
        })
        .then(() => {
          EventBus.$emit('showSuccessMessage', 'Сохранено')
          this.refresh()
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', 'Ошибка')
        })
    },
    remove () {
      let { layer } = this.feature
      let formatWFS = new WFS()
      let gml = new GML({
        featureNS: layer._ds.targetNamespace,
        featurePrefix: layer._ds.targetPrefix,
        featureType: layer._ds.typeName,
        srsName: 'EPSG:3857'
      })
      let node = formatWFS.writeTransaction(null, null, [this.feature], gml)
      let s = new XMLSerializer()
      let value = s.serializeToString(node)
      this.$axios
        .post(window.location.origin + '/geoserver/wfs', value, {
          headers: {
            'Content-Type': 'text/xml'
          },
          params: {
            service: 'wfs',
            outputFormat: ''
          }
        })
        .then(() => {
          EventBus.$emit('showInfoMessage', 'Объект удален')
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', 'Ошибка')
        })
    }
  },
  computed: {
    actions () {
      return [OPEN_ISSUES, SHARE, EDIT, REMOVE]
        .filter(action => this.isAuthenticated || !action.onlyAuthenticated)
    },
    name () {
      return this.feature.values_['dName'] || this.feature.layer.name
    }
  }
}
</script>
