var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"700","persistent":"","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',[_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('templateName')},model:{value:(_vm.poiTemplate.name),callback:function ($$v) {_vm.$set(_vm.poiTemplate, "name", $$v)},expression:"poiTemplate.name"}}),_c('v-select',{attrs:{"items":_vm.graphTypeList,"outlined":"","label":_vm.$t('type')},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("graphTypeList." + item)))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("graphTypeList." + item)))+" ")]}}]),model:{value:(_vm.poiTemplate.graphType),callback:function ($$v) {_vm.$set(_vm.poiTemplate, "graphType", $$v)},expression:"poiTemplate.graphType"}}),_c('v-select',{attrs:{"items":_vm.lpStyleList,"outlined":"","return-object":"","item-text":"name","label":_vm.$t('defaultStyle')},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"pa-3",attrs:{"align":"center"}},[_vm._v(" "+_vm._s(item.name)+" "),_c('v-spacer'),(item.icon)?_c('v-img',{staticStyle:{"height":"auto","max-width":"50px"},attrs:{"src":item.icon}}):_c('div',{style:({ width: '80px', border: ("2px solid " + (item.stroke)), height: '20px', backgroundColor: item.fill})})],1)]}}]),model:{value:(_vm.poiTemplate.defaultLayerPoiStyle),callback:function ($$v) {_vm.$set(_vm.poiTemplate, "defaultLayerPoiStyle", $$v)},expression:"poiTemplate.defaultLayerPoiStyle"}}),_c('v-combobox',{attrs:{"outlined":"","multiple":"","small-chips":"","label":_vm.$t('button.printTemplates'),"items":_vm.printTemplateList},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_vm._v(_vm._s(item.name))]}},{key:"selection",fn:function(data){return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(data.item.name))])]}}]),model:{value:(_vm.poiTemplate.printTemplates),callback:function ($$v) {_vm.$set(_vm.poiTemplate, "printTemplates", $$v)},expression:"poiTemplate.printTemplates"}}),_c('lps-rule-editor',{ref:"lpsRuleEditorDlg",attrs:{"template":_vm.poiTemplate}}),_c('vuedraggable',{attrs:{"handle":".handle"},model:{value:(_vm.poiTemplate.fields),callback:function ($$v) {_vm.$set(_vm.poiTemplate, "fields", $$v)},expression:"poiTemplate.fields"}},_vm._l((_vm.poiTemplate.fields),function(field,index){return _c('poi-template-field',{key:index,attrs:{"value":field},on:{"remove":_vm.removeLpField}})}),1),_c('v-card-title',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.addField}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$t('addField'))+" ")],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){_vm.dialog=false}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-spacer'),(_vm.poiTemplate.isActive)?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.setAbility('SYSTEM_ADMIN')),expression:"setAbility('SYSTEM_ADMIN')"}],attrs:{"outlined":"","color":"red"},on:{"click":_vm.deactivate}},[_vm._v(" "+_vm._s(_vm.$t('deactivate'))+" ")]):_vm._e(),(!_vm.poiTemplate.isActive)?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.setAbility('SYSTEM_ADMIN')),expression:"setAbility('SYSTEM_ADMIN')"}],attrs:{"outlined":"","color":"green"},on:{"click":_vm.activate}},[_vm._v(" "+_vm._s(_vm.$t('activate'))+" ")]):_vm._e(),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.setAbility('ADMIN')),expression:"setAbility('ADMIN')"}],attrs:{"outlined":"","color":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }