<template>
  <vue-document-editor
    v-if="content"
    ref="vueDocumentEditor"
    :content.sync="content"
    class="print-template"
    @update:content="getTemplates"
  />
</template>

<script>
import VueDocumentEditor from 'vue-document-editor'
import VueFileToolbarMenu from 'vue-file-toolbar-menu'
import { componentFromString } from '@/components/admin/print-template/utils/utils'

export default {
  name: 'PrintTemplate',
  components: { VueFileToolbarMenu, VueDocumentEditor },
  props: {
    selectedTemplate: Object,
    templateProps: Object
  },
  data: () => ({
    content: null
  }),
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.content = this.getContent(this.selectedTemplate)
    },
    getTemplates () {
      return this.$refs.vueDocumentEditor.pages.map(page => page.prev_innerHTML)
    },
    getContent (template) {
      if (template.massPrint && this.templateProps) {
        const content = []
        this.templateProps.pois.forEach(lp => {
          template.pages
            .sort((prev, next) => prev.pageNumber - next.pageNumber)
            .forEach(page => content.push({
              template: componentFromString(page.template),
              props: {
                value: { pois: [lp] },
                printData: null
              }
            }))
        })
        return content
      } else {
        return template.pages
          .sort((prev, next) => prev.pageNumber - next.pageNumber)
          .map(it => ({
            template: componentFromString(it.template),
            props: {
              value: this.templateProps ? { ...this.templateProps } : null,
              printData: null
            }
          }))
      }
    },
    print (printData) {
      this.$refs.vueDocumentEditor.pages.forEach(page => page.props.printData = printData)
      this.$nextTick(() => {
        window.print()
        setTimeout(() => {
          this.$refs.vueDocumentEditor.pages.forEach(page => page.props.printData = null)
        }, 100)
      })
    }
  },
  watch: {
    selectedTemplate () {
      this.init()
    }
  }
}
</script>

<style scoped>
.print-template {
  font-family: "Times New Roman Regular", sans-serif;
}
</style>
