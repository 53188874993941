<template>
  <div style="width: 100%; height: 100%">
    <d-map
      v-if="layers"
      ref="map"
      :map="map"
      style="width: 100%; height: 100%"
    >
      <div
        v-if="!menu"
        v-show="!displayXS"
        style="cursor: pointer; position: fixed; width: 20px; height: 100%; z-index: 30"
        @click="menu=true">
        <div
          style="width: 25px; padding-right: 10px; margin-top: 45vh; background: rgb(96,96,96); border-radius: 0 6px 6px 0">
          <v-icon color="white" large left style="right: 6px">arrow_right</v-icon>
        </div>
      </div>
      <div
        v-if="menu"
        v-show="!displayXS"
        style="position: absolute; left: 0; bottom: 0; width: 280px; height: 100%; z-index: 5; background: white">
        <v-card-text>
          <span class="subtitle-1">Список слоев</span>
          <map-config
            :layers.sync="layers"
            class="mt-2"
            @refresh-map="$refs.map.refresh()"/>
        </v-card-text>
        <div style="width: 100%; height: 40px; cursor: pointer; bottom: 0; position: absolute;"
             @click="menu=false">
          <v-divider class="mb-2"/>

          <span class="pa-3">
            Скрыть
          </span>
        </div>
      </div>
    </d-map>
    <v-bottom-sheet ref="searchBar" v-model="searchBar" max-width="600">
      <v-card class="py-3" style="height: 90vh; overflow-y: auto">
        <portal-target name="map-search"/>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import MapConfig from '@/components/map/helpers/MapConfig.vue'
import ShareDataDialog from '@/components/utils/ShareDataDialog.vue'
import DMap from '@/components/map/DMap.vue'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'

export default {
  name: 'PublicMapViewer',
  components: { DMap, ShareDataDialog, MapConfig },
  mixins: [displayServiceMixin],
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      map: null,
      menu: false,
      layers: null,
      supportedFormat: true,
      searchBar: false
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.map = JSON.parse(JSON.stringify(this.data))
      this.map.position = this.map.position ? JSON.parse(this.map.position) : null
      this.layers = this.map.layerConfigList
        .sort((a, b) => {
          return a.indexNumber > b.indexNumber ? 1 : -1
        })
        .map(it => {
          let layer = it.layer
          layer.visible = it.visible
          return layer
        })
    },
    search () {
      this.searchBar = true
    }
  }
}
</script>
