<template>
  <div>
    <component
      :is="getComponent(feature)"
      v-for="feature in features"
      ref="card"
      :feature="feature"
      @close="$emit('close')"
      @edit="$emit('edit')"
      @save="$emit('save')"
    />
  </div>
</template>

<script>
import ZwsFeatureCard from '@/components/map/zws/ZwsFeatureCard'
import LayerPoiFeatureCard from '@/components/map/LayerPoiFeatureCard'
import GeoServerFeatureCard from '@/components/map/geoserver/GeoServerFeatureCard'

export default {
  name: 'SelectedFeatureCard',
  components: { LayerPoiFeatureCard, ZwsFeatureCard, GeoServerFeatureCard },
  props: {
    features: Array
  },
  methods: {
    getComponent (feature) {
      if (feature.dComponent) {
        return feature.dComponent
      }
    },
    async save () {
      this.$refs.card[0].save()
    }
  }
}
</script>
