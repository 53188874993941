<template>
  <abstract-data-list ref="abstractDataList" type="REPORT" view="report-list">
    <template slot="actions">
      <v-btn @click="createNewItem"
             color="primary"
             outlined
             v-if="setAbility('MODERATOR')">
        {{ $t('button.create') }}
      </v-btn>
      <report-details-dialog @on-save="onSave" ref="reportDetailsDialog"/>
    </template>
  </abstract-data-list>
</template>

<script>
import AbstractDataList from '../utils/AbstractDataList'
import ReportDetailsDialog from './ReportDetailsDialog'

export default {
  name: 'ReportList',
  components: { ReportDetailsDialog, AbstractDataList },
  methods: {
    createNewItem () {
      this.$refs.reportDetailsDialog.open()
    },
    onSave () {
      this.$refs.abstractDataList.filterData()
    }
  }
}
</script>
